import React, { useState, useEffect, useLayoutEffect } from "react";
import Slider from "components/Slider/slider";
import Service from "components/Service/service";
import Product from "components/Product/product";
import { Row, Col, Spinner } from "reactstrap";
import apiConfig from "config/apiConfig";
import { catProductsUrl } from "../../api/endpoints";
import Api from "api/api";
import { currentState } from "../../selector";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions";
import * as actionsOrder from "modules/Orders/actions";
import { useCreateOrders } from "modules/Orders/hooks/useCreateOrders";
import { useUpdateOrders } from "modules/Orders/hooks/useUpdateOrders";
import { useDeleteOrders } from "modules/Orders/hooks/useDeleteOrders";
const List = (props) => {
  const { BASE_URL } = apiConfig;
  const {
    inputSearch,
    Rayons,
    Feature,
    currentUser,
    showSingIn,
    showBasket,
    showOrderSuccess,
  } = useSelector(currentState) || {};

  const [fetching, setFetching] = useState(false);
  const [skip, setSkip] = useState(0);
  const [listProduct, setListProduct] = useState([]);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  const actionsDispatchOrder = bindActionCreators(
    { ...actionsOrder },
    dispatch
  );

  // Get Order status Basket
  const [orderBasket, setOrderBasket] = useState([]);
  const [
    { fetchingOrders, error, dataCreateOrders },
    createOrders,
  ] = useCreateOrders();
  const [{ fetchingUpdateOrders, errorUpdate }, updateOrders] = useUpdateOrders(
    orderBasket[0]?.id
  );
  const [{ fetchingDelete, errorDelete }, deleteOrders] = useDeleteOrders(
    orderBasket[0]?.id
  );
  useLayoutEffect(() => {
    setOrderBasket([dataCreateOrders]);
  }, [dataCreateOrders]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api().get(`/orders/?$filter={"status": "basket"}`);
        setOrderBasket(data?.value);
        actionsDispatchOrder.emptyBasket();
      } catch (err) {
        console.log(err);
        // maybe display an error message
      }
    };
    fetchData();
  }, [showSingIn, fetchingUpdateOrders]);

  useEffect(() => {
    if (orderBasket.length > 0) {
      Array.isArray(orderBasket[0]?.products) &&
        orderBasket[0].products.map((item) =>
          actionsDispatchOrder.addBasket({
            ...item,
            can_add_to_basket: true,
            quantity: item.quantity,
          })
        );
    }
  }, [orderBasket]);

  // create order
  const onCreate = (payload) => {
    createOrders(payload);
  };

  //  list product
  const _ = require("lodash");
  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(
          catProductsUrl(skip, inputSearch, Rayons?.value, Feature?.value)
        );
        setFetching(false);
        setNewListProduct([]);
        skip === 0
          ? setListProduct(data?.value)
          : setListProduct(_.unionBy(listProduct, data?.value, "id"));
      } catch {
        setFetching(false);
        // maybe display an error message
      }
    };

    fetchData();
  }, [
    skip,
    inputSearch,
    Rayons?.value,
    Feature?.value,
    showBasket,
    showOrderSuccess,
    currentUser,
  ]);

  // Merge list product in basket
  const [newListProduct, setNewListProduct] = useState([]);
  useEffect(() => {
    const mergeData = () => {
      listProduct.map((item1) => {
        if (
          props.basket.length > 0 &&
          props.basket.find((item) => item.id === item1.id)
        ) {
          newListProduct.push(
            props.basket.find((item) => item.id === item1.id)
          );
        } else {
          newListProduct.push(item1);
        }
      });
      setNewListProduct(_.unionBy(newListProduct, [], "id"));
    };
    mergeData();
  }, [
    listProduct,
    // props.basket,
    fetchingOrders,
    fetchingUpdateOrders,
    orderBasket,
    error,
    showBasket,
    showOrderSuccess,
    currentUser,
  ]);

  // update  Order <<Vous avez déjà un panier en cours.>>
  useEffect(() => {
    if (error?.message === "Vous avez déjà un panier en cours.") {
      const payload = {
        products: props.basket.map((item) => ({
          product: item.id,
          quantity: item.quantity,
        })),
        status: "basket",
      };
      updateOrders(payload);
    }
  }, [fetchingOrders, error]);
  // update  Order
  useEffect(() => {
    if (orderBasket[0]?.id && props.basket?.length > 0) {
      const payload = {
        products: props.basket.map((item) => ({
          product: item.id,
          quantity: item.quantity,
        })),
        status: "basket",
      };
      updateOrders(payload);
    } else {
      deleteOrders();
    }
  }, [props.basket]);

  // Add to Basket
  const handleSelect = (id) => {
    const newProduct = [];
    newListProduct.map((item) => {
      if (item.id === id) {
        newProduct.push({ ...item, can_add_to_basket: true, quantity: 1 });
        actionsDispatchOrder.addBasket({
          ...item,
          can_add_to_basket: true,
          quantity: 1,
        });
        onCreate({
          products: [
            {
              product: item.id,
              quantity: 1,
            },
          ],
          status: "basket",
        });
      } else {
        newProduct.push({ ...item });
      }
    });
    setNewListProduct(newProduct);
  };

  // More Quantity
  const addQuantity = (id) => {
    const newProduct = [];
    newListProduct.map((item) => {
      if (item.id === id) {
        newProduct.push({ ...item, quantity: item.quantity + 1 });
        actionsDispatchOrder.addBasket({
          ...item,
          can_add_to_basket: true,
          quantity: item.quantity + 1,
        });
      } else {
        newProduct.push({
          ...item,
        });
      }
    });
    setNewListProduct(newProduct);
  };

  // Less Quantity

  const lessQuantity = (id) => {
    const newProduct = [];
    newListProduct.map((item) => {
      if (item.id === id) {
        newProduct.push({ ...item, quantity: item.quantity - 1 });
        if (item.quantity - 1 === 0) {
          actionsDispatchOrder.deleteBasket(item.id);
        } else {
          actionsDispatchOrder.addBasket({
            ...item,
            can_add_to_basket: true,
            quantity: item.quantity - 1,
          });
        }
      } else {
        newProduct.push({
          ...item,
        });
      }
    });
    setNewListProduct(newProduct);
  };

  // Go To details product :
  const detailsProduct = (item) => {
    props.actions.setCurrentProduct(item);
    props.history.push("/home/details");
  };

  useEffect(() => {
    setSkip(0);
  }, [Rayons, Feature]);

  return (
    <div className="">
      {Rayons?.name && Feature?.name ? (
        <>
          <div className="headerRayons text-bold mb-2">
          {Rayons?.name} / {Feature?.name}
          </div>
          <div className="breadcrumbRayons  text-Regular ml-4 mb-4">
            <span
              onClick={() => {
                actionsDispatch.setRayons({});
                actionsDispatch.setFeature({});
              }}
              style={{ cursor: "pointer" }}
            >{`Accueil${"  "}>`}</span>
            {`   Rayons${"  "}>  ${Rayons?.name} / ${Feature?.name}`}
          </div>
        </>
      ) : Rayons?.name ? (
        <>
          <div className="headerRayons text-bold mb-2">{Rayons?.name}</div>
          <div className="breadcrumbRayons  text-Regular ml-4 mb-4">
            <span
              onClick={() => {
                actionsDispatch.setRayons({});
              }}
              style={{ cursor: "pointer" }}
            >{`Accueil${"  "}>`}</span>
            {`   Rayons${"  "}>  ${Rayons?.name}`}
          </div>
        </>
      ) : Feature?.name ? (
        <>
          <div className="headerRayons text-bold mb-2">{Feature?.name}</div>
          <div className="breadcrumbRayons  text-Regular ml-4 mb-4">
            <span
              onClick={() => {
                actionsDispatch.setFeature({});
              }}
              style={{ cursor: "pointer" }}
            >{`Accueil${"  "}>`}</span>
            {`${"  "}${Feature?.name}`}
          </div>
        </>
      ) : (
        <>
          <Slider />
          <Service />
        </>
      )}

      <div className="app-main">
        <Row>
          {newListProduct.map((item) => (
            <Col
              className="ProductDisabled"
              disabled={
                item.stock === 0 && !item.enabledInOutOfStock ? true : false
              }
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <Product
                image={`${BASE_URL}${item?.imageUrl}`}
                name={item.name}
                description={item.shortDescription}
                priceCategory={currentUser?.priceCategory}
                breakingMessage={
                  item.stock === 0 && !item.enabledInOutOfStock
                    ? item.breakingMessage
                    : null
                }
                priceTtc={
                  item.priceTtc
                    ? item.priceTtc
                    : currentUser?.priceCategory === "A"
                    ? item.priceATtc
                    : currentUser?.priceCategory === "B"
                    ? item.priceBTtc
                    : currentUser?.priceCategory === "C"
                    ? item.priceCTtc
                    : currentUser?.priceCategory === "D"
                    ? item.priceDTtc
                    : currentUser?.priceCategory === "E" && item?.remiseE > 0
                    ? parseFloat(
                        item.priceETtc - (item.priceETtc * item?.remiseE) / 100
                      ).toFixed(2)
                    : parseFloat(
                        item.priceETtc -
                          (item.priceETtc * item?.remiseE || 0) / 100
                      ).toFixed(2)
                }
                priceHt={
                  item.price
                    ? item.price
                    : currentUser?.priceCategory === "A"
                    ? item.priceA
                    : currentUser?.priceCategory === "B"
                    ? item.priceB
                    : currentUser?.priceCategory === "C"
                    ? item.priceC
                    : currentUser?.priceCategory === "D"
                    ? item.priceD
                    : currentUser?.priceCategory === "E" && item?.remiseE > 0
                    ? parseFloat(
                        item.priceE - (item.priceE * item?.remiseE) / 100
                      ).toFixed(2)
                    : (
                        parseFloat(item.priceE) -
                        parseFloat((item.priceE * item?.remiseE || 0) / 100)
                      ).toFixed(2)
                }
                currentUser={currentUser}
                remise={item.remiseE}
                handleSelect={() =>
                  currentUser !== null
                    ? handleSelect(item.id)
                    : actionsDispatch.setSignIn(true)
                }
                can_add_to_basket={item.can_add_to_basket}
                quantity={item.quantity}
                addQuantity={() => addQuantity(item.id)}
                lessQuantity={() => lessQuantity(item.id)}
                detailsProduct={() => detailsProduct(item)}
              />
            </Col>
          ))}
        </Row>
        {listProduct.length > 11 && (
          <>
            <Row>
              <Col xs="12" sm="12">
                <div className="footerAfficherPlus">
                  <span
                    onClick={() => setSkip(skip + 12)}
                    className="afficherPlus text-bold text-center mb-4"
                    style={{ cursor: "pointer" }}
                  >
                    {fetching ? <Spinner size="sm" /> : "Afficher plus"}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default List;
