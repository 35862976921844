// Api
import { Api } from "api";

import reportError from "lib/errorHandler";

// some endpoints
import { meUrl, uploadUrl,uploadReferencesUrl,uploadFournisseurUrl } from "api/endpoints";

import {
  GET_CURRENT_USER_R,
  GET_CURRENT_USER_F,
  SET_CURRENT_USER,
  LOGOUT_S,
  SET_SIDEBAR_OPEN,
  SET_ACTIVE_TAB,
  SET_CURRENT_SEARCH,
  SET_SEARCH,
  SET_RAYONS,
  SET_FEATURE,
  SET_SIGNIN,
  SET_FORGOTPASS,
  SET_SIGNUP,
  SET_BASKET,
  SET_ORDER,
  SET_SIGNUP_SU,
  SET_EMAIL,
  SET_ORDER_SU,
} from "actionsTypes";

export const setSidebarOpen = (payload) => ({
  type: SET_SIDEBAR_OPEN,
  payload,
});

export const setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload,
});

export const logoutSuccess = () => ({
  type: LOGOUT_S,
});
export const setEmail = (payload) => ({
  type: SET_EMAIL,
  payload
});
export const setOrderSuccuss = (payload) => ({
  type: SET_ORDER_SU,
  payload
});

export const getCurrentUserRequest = () => ({
  type: GET_CURRENT_USER_R,
});

export const getCurrentUserFailed = (payload) => ({
  type: GET_CURRENT_USER_F,
  payload,
});

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});
export const setCurrentSearch = (payload) => ({
  type: SET_CURRENT_SEARCH,
  payload,
});
export const setSearch = (payload) => ({
  type: SET_SEARCH,
  payload,
});

export const setRayons = (payload) => ({
  type: SET_RAYONS,
  payload,
});
export const setFeature = (payload) => ({
  type: SET_FEATURE,
  payload,
});

export const setSignIn = (payload) => ({
  type: SET_SIGNIN,
  payload,
});
export const setSignUp = (payload) => ({
  type: SET_SIGNUP,
  payload,
});
export const setSignUpSu = (payload) => ({
  type: SET_SIGNUP_SU,
  payload,
});

export const setBasket = (payload) => ({
  type: SET_BASKET,
  payload,
});

export const setForgotPss = (payload) => ({
  type: SET_FORGOTPASS,
  payload,
});
export const setOrder = (payload) => ({
  type: SET_ORDER,
  payload,
});

export const getMe = () => {
  return (dispatch) => {
    dispatch(getCurrentUserRequest());
    Api()
      .get(meUrl())
      .then((data) => {
        if (data) {
          dispatch(setCurrentUser(data));
        } else {
        }
      })
      .catch((err) => {
        dispatch(getCurrentUserFailed());
        reportError("get me error", err);
      });
  };
};

export const uploadFiles = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadUrl(), payload, true);
      resolve(data);
    } catch (err) {
      reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};

export const uploadFileReferences = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadReferencesUrl(), payload, true);
      resolve(data);
    } catch (err) {
      reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};export const uploadFileFournisseur = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadFournisseurUrl(), payload, true);
      resolve(data);
    } catch (err) {
      reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};