import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import AppInput from "components/AppInput";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import * as actions from "actions";
import { currentSelect } from "../../modules/App/selector";
import { detailsStateSelect } from "modules/Orders/selector";
import { push } from "connected-react-router";
import { Api } from "api";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";

const BarSearch = (props) => {
  const { inputSearch, showSingIn, currentUser, showBasket } =
    useSelector(currentSelect) || {};
  const { current } = useSelector(detailsStateSelect) || {};
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  const [value, setValue] = useState(inputSearch);
  const [fetching, setFecthing] = useState(false);
  const onLogout = async () => {
    try {
      setFecthing(true);
      await Api().get("/auth/signout");
      setFecthing(false);
      actionsDispatch.logoutSuccess();
      sessionStorage.clear();
      localStorage.clear();
      dispatch(push(`/home/list`));
    } catch {
      setFecthing(false);
    } finally {
      setFecthing(false);
      actionsDispatch.logoutSuccess();
      sessionStorage.clear();
      localStorage.clear();
      dispatch(push(`/home/list`));
    }
  };

  useEffect(() => {
    actionsDispatch.setSearch(value);
  }, [value]);

  const [isConnected, setConnected] = useState();
  return (
    <div className="BarSearch mb-4">
      <Row>
        <Col className="logo" xs="12" sm="12" md="3">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(push(`/home/list`));
              actionsDispatch.setRayons({});
              actionsDispatch.setFeature({});
            }}
          >
            <img alt="+" src={require("assets/img/logo.png")} />
          </div>
        </Col>
        <Col xs="12" sm="12" md="6">
          <div className="containerInputSearch">
            <AppInput
              className="inputSearch"
              // id="login-pwd"
              name="pwd"
              type="text"
              placeholder="Rechercher un produit"
              value={value}
              onChange={(t) => setValue(t)}
              leftaddon={
                <div className="input-addon">
                  <img alt="+" src={require("assets/img/iconSearch.png")} />
                </div>
              }
            />
          </div>
        </Col>
        <Col className="logo" xs="12" sm="12" md="3">
          <div
            onClick={() =>
              currentUser !== null ? "" : actionsDispatch.setSignIn(!showSingIn)
            }
            className="containerCompte"
          >
            {currentUser !== null ? (
              <UncontrolledDropdown className="button-dropdown">
                <DropdownToggle
                  caret
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdown"
                  tag="a"
                  onClick={(e) => e.preventDefault()}
                >
                  <>
                    {" "}
                    <div
                      className="image"
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <img alt="+" src={require("assets/img/iconCompte.png")} />
                    </div>
                    <div className="text nameCompte text-regular">
                      {" "}
                      Bonjour {currentUser.name.first}{" "}
                      {currentUser?.name?.last?.split("")?.length > 30
                        ? `${currentUser?.name?.last.slice(0, 5)} ...`
                        : currentUser?.name?.last}
                    </div>
                  </>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdown">
                  {/* <DropdownItem to="/passwordreset" tag={Link}>
                    <span className="home-navlink text-semi">
                      Changer mot de passe
                    </span>
                  </DropdownItem> */}
                  <DropdownItem onClick={(e) => onLogout()} tag={Link}>
                    <span className="home-navlink text-semi">
                      Se déconnecter
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <>
                <div
                  className="image"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img alt="+" src={require("assets/img/iconCompte.png")} />
                </div>
                <div className="text nameCompte text-regular">
                  <span>Compte</span>
                </div>
              </>
            )}
          </div>

          <Badge
            badgeContent={current.length > 0 ? current.length : 0}
            color="#F7E010"
          >
            <div
              onClick={() =>
                currentUser !== null
                  ? actionsDispatch.setBasket(!showBasket)
                  : actionsDispatch.setSignIn(!showSingIn)
              }
              className="containerPanier"
            >
              <div className="image">
                <img alt="+" src={require("assets/img/iconPanier.png")} />
              </div>
              <div className="text text-regular">Panier</div>
            </div>
          </Badge>
        </Col>
      </Row>
    </div>
  );
};

export default BarSearch;
