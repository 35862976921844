import { createSelector } from "reselect";

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);
export const currentState = createSelector(
  (state) => state.app,
  (app) => app
);

export const listStateSelect = createSelector(
  (state) => state.home,
  (home) => home?.list
);

export const detailsStateSelect = createSelector(
  (state) => state.home,
  (home) => home.details
);

export const createStateSelect = createSelector(
  (state) => state.home,
  (home) => home?.create
);
