import React from "react";
import { Row, Col } from "reactstrap";

const Service = () => {
  return (
    <>
      <hr />

      <div className="app-main">
        <div className="Service">
          <Row>
            <Col md={4} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="text-demi">
                  <img
                    width="80px"
                    alt="+"
                    src={require("assets/img/service1.png")}
                  />
                </div>
                <div style={{ color: "#607C88" }} className="text-demi ml-2">
                  Paiement <br /> sécurisé
                </div>
              </div>
            </Col>
            <Col md={4} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="text-demi ">
                  <img
                    width="80px"
                    alt="+"
                    src={require("assets/img/service2.png")}
                  />
                </div>
                <div style={{ color: "#607C88" }} className="text-demi ml-2">
                  Livraison offerte <br /> dès 150€
                </div>
              </div>
            </Col>
            <Col md={4} sm={6} xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="text-demi">
                  <img
                    width="80px"
                    alt="+"
                    src={require("assets/img/service3.png")}
                  />
                </div>
                <div style={{ color: "#607C88" }}className="text-demi ml-2">
                  Chronofresh vous <br /> livre chez vous
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Service;
