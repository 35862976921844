import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeContext } from "contextProviders/ThemeProvider";

import * as actions from "../../actions";
import { listStateSelect, currentUserStateSelect } from "../../selector";
import { detailsStateSelect } from "modules/Orders/selector";



import List from "./component";

const ListContainer = (props) => {
  const theme = useContext(ThemeContext);
  const list = useSelector(listStateSelect);
  const { roles } = useSelector(currentUserStateSelect) || {};
  const { current } = useSelector(detailsStateSelect) || {};
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <List
      userRole={roles?.[0]}
      theme={theme}
      list={list}
      basket={current}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default ListContainer;
