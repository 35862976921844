import { ADD_BASKET,DELETE_BASKET , EMPTY_BASKET } from "./actionsTypes";

export const addBasket = (payload) => ({
  type: ADD_BASKET,
  payload,
});
export const deleteBasket = (payload) => ({
  type: DELETE_BASKET,
  payload,
});
export const emptyBasket = (payload) => ({
  type: EMPTY_BASKET,
  payload,
});