import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Modal, ModalBody, Button, Row, Col } from "reactstrap";

import { useSelector } from "react-redux";
import { Api } from "api";
import { logoutSuccess } from "actions";
import { currentUserSelect } from "../../modules/App/selector";
import AppInput from "components/AppInput";
import { useSignUp } from "./hooks/useSignUp";
import ErrorLabel, { usePasswordCheck } from "hooks/usePasswordCheck";
import CONFIG from "config/apiConfig";
const SignUp = (props) => {
  const { BASE_URL, API_VERSION, REQUEST_TIMER } = CONFIG;
  const [{ fetching, error }, signup] = useSignUp();
  const [state, setState] = useState({});
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const { inputSearch } = useSelector(currentUserSelect) || {};
  const { isValid: passwordValid } = usePasswordCheck(state.password || "");
  const formValid =
    !state.email ||
    !state.password ||
    !state.firstname ||
    !state.lastname ||
    !state.phone ||
    !state.city ||
    !state.zipcode ||
    !check ||
    state.confirmPassword !== state.password ||
    !passwordValid ||
    !state.address;
  const [openPassword, setOpenPassword] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const onSubmit = (e) => {
    e.preventDefault();
    const {
      email,
      password,
      firstname,
      lastname,
      phone,
      city,
      zipcode,
      address,
      raisonSocial,
    } = state;
    const payload = {
      email: email.trim(),
      password,
      socialReason: isIndividual ? "N/A" : raisonSocial,
      name: {
        first: firstname,
        last: lastname,
      },
      phone,
      city,
      zipcode,
      address: address,
      // location: { coordinates },
      role: "user",
      isIndividual,
    };
    signup(payload);
  };

  return (
    <Modal
      size="lg"
      isOpen={props.isOpen}
      toggle={() => {
        if (!fetching) {
          props.onCloseModal();
        }
      }}
    >
      <div className="modal-header">
        <button
          onClick={(e) => {
            if (!fetching) {
              props.onCloseModal();
            }
          }}
          aria-label="Close"
          className="close"
          type="button"
        >
          <img
            src={require("assets/img/iconClose.png")}
            className=""
            width="16px"
            alt=">"
          />
        </button>
      </div>
      <ModalBody className="signup">
        <h5 className="modal-title text-bold mb-4">Inscription</h5>
        <Row>
          <Col md={6}>
            <AppInput
              label="Prénom"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"text"}
              value={state.firstname}
              onChange={(t) => setState({ ...state, firstname: t })}
            />
          </Col>
          <Col md={6}>
            <AppInput
              label="Nom"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"text"}
              value={state.lastname}
              onChange={(t) => setState({ ...state, lastname: t })}
            />
          </Col>
        </Row>
        <Row>
          {/* <Col md={6}>
            <AppInput
              label="Vous êtes"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"text"}
              value={value}
              onChange={(t) => setState({...state , t})}
            />
          </Col> */}
          <Col md={6}>
            <AppInput
              label="Email"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"text"}
              value={state.email}
              onChange={(t) => setState({ ...state, email: t })}
            />
          </Col>
          <Col md={6}>
            <AppInput
              label="Téléphone"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"number"}
              value={state.phone}
              onChange={(t) => setState({ ...state, phone: t })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <AppInput
              label="Mot de passe "
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={openPassword ? "text" : "password"}
              value={state.password}
              onChange={(t) => setState({ ...state, password: t })}
              leftaddon={
                <div
                  onClick={() => setOpenPassword(!openPassword)}
                  className="input-addon"
                >
                  <img
                    alt="+"
                    src={
                      openPassword
                        ? require("assets/img/eye-b.png")
                        : require("assets/img/eye.png")
                    }
                  />
                </div>
              }
            />
            {state.password && <ErrorLabel password={state.password} />}
          </Col>
          <Col md={6}>
            <AppInput
              label="Mot de passe de nouveau"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={openConfirm ? "text" : "password"}
              value={state.confirmPassword}
              leftaddon={
                <div
                  onClick={() => setOpenConfirm(!openConfirm)}
                  className="input-addon"
                >
                  <img
                    alt="+"
                    src={
                      openConfirm
                        ? require("assets/img/eye-b.png")
                        : require("assets/img/eye.png")
                    }
                  />
                </div>
              }
              onChange={(t) => setState({ ...state, confirmPassword: t })}
            />
            {state.password &&
              state.confirmPassword &&
              state.confirmPassword !== state.password && (
                <div style={{ color: "red" }} className="text-black">
                  Les mots de passe ne sont pas identiques
                </div>
              )}
          </Col>
        </Row>

        <Row>
          {/* <Col md={6}>
            <AppInput
              label="Nom de l’entreprise "
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"text"}
              value={value}
              onChange={(t) => setState({...state , t})}
            />
          </Col> */}
        </Row>
        <Row>
          <Col md={12}>
            <AppInput
              label="Adresse"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"text"}
              value={state.address}
              onChange={(t) => setState({ ...state, address: t })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <AppInput
              label="Ville"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"text"}
              value={state.city}
              onChange={(t) => setState({ ...state, city: t })}
            />
          </Col>
          <Col md={6}>
            <AppInput
              label="Code postal"
              className="inputSearch"
              required
              id="login-pwd"
              name="pwd"
              placeholder=""
              type={"number"}
              value={state.zipcode}
              onChange={(t) => setState({ ...state, zipcode: t })}
            />
          </Col>
        </Row>
        <Row>
          {check ? (
            <Col className="mb-4 mt-2">
              <img
                onClick={() => setCheck(!check)}
                width="20px"
                alt="+"
                src={require("assets/img/checked.png")}
              />
              <span onClick={() => setCheck(!check)} className="ml-2">
                En continuant vous acceptez nos{" "}
              </span>{" "}
              <a
                target="_blank"
                 href={`${BASE_URL}/#/cgus`}
                style={{ color: "#1A96C4" }}
                className="bottom-action my-4 text-bold"
              >
                Conditions générales d’utilisation
              </a>{" "}
              <br />
              <a
                target="_blank"
                 href={`${BASE_URL}/#/cgus`}
                style={{ color: "#1A96C4", marginLeft: "30px" }}
                className="bottom-action my-4 text-bold"
              >
                et notre politique de confidentialité
              </a>
            </Col>
          ) : (
            <Col className="mb-4 mt-2">
              <img
                onClick={() => setCheck(!check)}
                width="20px"
                alt="+"
                src={require("assets/img/notChecked.png")}
              />
              <span onClick={() => setCheck(!check)} className="ml-2">
                En continuant vous acceptez nos{" "}
              </span>{" "}
              <a
                target="_blank"
                href={`${BASE_URL}/#/cgus`}
                style={{ color: "#1A96C4" }}
                className="bottom-action my-4 text-bold"
              >
                Conditions générales d’utilisation
              </a>
              <br />
              <a
                target="_blank"
                 href={`${BASE_URL}/#/cgus`}
                style={{ color: "#1A96C4", marginLeft: "30px" }}
                className="bottom-action  text-bold"
              >
                et notre politique de confidentialité
              </a>
            </Col>
          )}
        </Row>
        {error && (
          <h6 className="error">{error.message || "Erreur de connexion"}</h6>
        )}
        <div className="modal-footer">
          <div
            onClick={() => {
              props.onShowSignIn();
              props.onCloseModal();
            }}
            className="TextSignUp text-demi"
          >
            J’ai déjà un Compte
          </div>
          <Button
            disabled={formValid}
            className="BtnSignUp text-bold"
            color="default"
            type="button"
            onClick={(e) => onSubmit(e)}
          >
            Valider
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SignUp;
