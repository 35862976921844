import React, { useState } from "react";

import { Row, Col, Label, Button, Spinner, FormGroup, Input } from "reactstrap";

import PageDescription from "components/PageDescription";
import AppInput from "components/AppInput";
import CustomSimpleSelect from "components/CustomSimpleSelect";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { useCreateDemandes } from "../../hooks/useCreateDemandes";
import Switch from "../../../../components/Switch";
import { SettingsEthernet } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { currentUserStateSelect } from "../../selector";
import { useUploadFiles } from "../../hooks/useUploadFiles";
const Create = (props) => {
  const [state, setState] = useState({ $q: "", file: "" });
  const { role } = useSelector(currentUserStateSelect) || {};
  const [{ fetchingFile, errorFile, idsFile }, uploadFiles] = useUploadFiles();

  const dispatch = useDispatch();

  //// Handle UploadFiles
  const HandleUploadFile = async () => {
    let fileUpload;
    const fileEvent = state.file;
    if (fileEvent) {
      fileUpload = new FormData();
      fileUpload.append("file", fileEvent);
      uploadFiles(fileUpload);
      // setState({ ...state, idsFile: idsFile });
    }
  };
  //// Handle change Name File
  const handleChangeNameFile = (event) => {
    setState({
      ...state,
      fileName: event.target?.value,
      file: event.target.files[0],
    });
  };

  return (
    <div className="app-main">
      <PageDescription title="Créer Demandes" />
      {idsFile && idsFile.length > 1 ? (
        <div className="creation-plusieur-form">
          <div>
            <div style={{ textAlign: "center" }} className="title text-bold ">
              Import terminé
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                className={` space-left btnHome ${props.theme}`}
                onClick={(e) => dispatch(push("/demandes/list"))}
                size="lg"
              >
                <span className="text-bold "> Retourner à l’accueil </span>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="creation-plusieur-form">
          <div>
            <div className=" text-center title text-bold">
              Veuillez chargez le fichier csv
            </div>
            <div style={{ textAlign: "center" }}>
              <label
                style={{ cursor: "pointer" }}
                className="mt-2"
                name="file"
                for="file"
              >
                <img width="29px" src={require("assets/img/iconPdf.png")} />
                <span className="ml-2">
                  {state.fileName
                    ? state.fileName
                    : "Téléverser la pièce jointe (Fichier csv)"}
                </span>
                <input
                  style={{ display: "none" }}
                  id="file"
                  type="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={(file) => {
                    handleChangeNameFile(file);
                    // handleChangeTypeFile(item.id, file);
                  }}
                />
              </label>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                className={` space-left btnNext ${props.theme}`}
                onClick={
                  (e) => HandleUploadFile()
                  // dispatch(push("/Demandes/list"));
                }
                size="lg"
                disabled={!state.fileName}
              >
                {fetchingFile ? (
                  <>
                    {" "}
                    <span className="text-bold"> En cours ... </span>{" "}
                    <Spinner size="sm" />
                  </>
                ) : (
                  <span className="text-bold"> Envoyer </span>
                )}
              </Button>
            </div>
            <div>
              <h6 className="auth-error">
                {((idsFile && idsFile.length === 0) ||
                  (errorFile && !errorFile.success)) &&
                  "Fichier erroné merci de respecter le modèle de document"}
              </h6>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;
