// routes components
import App from "modules/App";
// import Auth from "modules/Auth";
// import Landing from 'modules/Landing';
import Cgus from 'components/Cgus/cgus';

const routes = [
  // {
  //   path: '/landing',
  //   component: Landing,
  //   exact: true,
  // },
  {
    path: "/cgus",
    component: Cgus,
  },
  {
    // path: `/home/list`,
    component: App,
  },
];

export default routes;
