import React from "react";
import classNames from "classnames";
import { FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/css/datePickerForm.css";
import fr from "date-fns/locale/fr";
import moment from "lib/moment";

const DateTimeSimpel = ({
  label,
  required,
  value,
  onChange,
  leftaddon,
  placeholderText,
  disabled,
  placeholder,
  isClearable,
  minDate,
  ...props
}) => {
  return !disabled ? (
    <FormGroup className="datePickerForm">
      <Label>
        {label}
        {required && <span>*</span>}
      </Label>
      <DatePicker
        className="creation-date"
        inputProps={{
          className: classNames("custom-input", {
            // matriculeLast: props.className === 'matricule-last',
          }),
          placeholderText: { placeholderText },
        }}
        minDate={minDate}
        placeholderText={placeholderText}
        dateFormat="dd/MM/yyyy"
        selected={value ? new Date(moment(value).format("YYYY/MM/DD")) : null}
        onChange={onChange}
        disabled={disabled}
        timeFormat={false}
        isClearable={isClearable}
        locale={fr}
      />
      {leftaddon && leftaddon}
    </FormGroup>
  ) : (
    <FormGroup>
      <Label className="text-black">
        {label}
        {required && <span>*</span>}
      </Label>
      <Input
        className="custom-input-disabled"
        disabled={disabled}
        name={`_input`}
        placeholder={placeholder}
        type="text"
        value={value}
        autoComplete="off"
        step=".01"
        {...props}
      />
      {leftaddon && leftaddon}
    </FormGroup>
  );
};

export default DateTimeSimpel;
