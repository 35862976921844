import React from "react";

function LoaderFull() {
  return (
    <div>
      <div className="loading">Loading&#8230;</div>

      {/* <div class="content">
        <h3>stuff goes in here!</h3>
      </div> */}
    </div>
  );
}

export default LoaderFull;
