import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Redirect } from "react-router";

// api
import { Api } from "api";

import { setCurrentUser } from "actions";
import { meUrl } from "api/endpoints";

const userStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

export function useCheckAuth() {
  const currentUser = useSelector(userStateSelect);
  const [isConnected, setConnected] = useState(currentUser !== null);
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAuth() {
      try {
        const user = await Api().get(meUrl());
        if (user) {
          setConnected(true);
          dispatch(setCurrentUser(user));
        }
      } catch (e) {
        console.log("e", e);
        setConnected(false);
      } finally {
        setIsReady(true);
      }
    }

    checkAuth();
  }, [dispatch]);

  return { isConnected, isReady };
}

export default function CheckAuth(Component, ...props) {
  // const { isConnected, isReady } = useCheckAuth();
  // if (isReady) {
  //   return isConnected ? (
  //     <Component isConnected={isConnected} {...props} />
  //   ) : (
  //     <Redirect
  //       to={{
  //         pathname: "/auth/signin",
  //         state: { from: props.location },
  //       }}
  //     />
  //   );
  // }

  return <div />;
}
