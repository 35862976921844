import React from 'react'

function Cgus() {
  return (
    <div className="container ">
        Le site Internet www.yamiz.fr et les applications YAMIZ (ci-après "le Site") sont un espace de commerce électronique édité et exploité par la société YAMIZ, immatriculée au registre du commerce et des sociétés de Bourg en Bresse sous le numéro 792982548 et dont le siège social se trouve 5 rue des crêts 01000 Bourg en Bresse

Le Site propose un service de commande de produits à distance livrés à domicile et au professionnel aux conditions ci-après définies.

1/ Champ d'application des conditions générales de vente

Les présentes conditions générales de vente s'appliquent, sans restriction ni réserve à l'ensemble des achats effectués sur le Site par toute personne physique majeure située dans une zone de livraison desservie par YAMIZ (voir article 5) (Ci-après dénommée le/les "Client(s)").

Le contrat résultant de la commande effective du Client relève de la règlementation de la vente à distance, telle qu'elle résulte des dispositions législatives et règlementaires en vigueur en France, ainsi que des dispositions énoncées ci-après.

Ces conditions s'appliquent à l'exclusion de toute autre, et notamment celles applicables pour les ventes en magasin ou au moyen d'autres circuits de commercialisation. YAMIZ se réserve la faculté de modifier les présentes conditions générales de vente à tout moment. En cas de modification, les conditions applicables à la commande sont celles en vigueur à la date de passation de la commande.

2/ Acceptation des conditions générales de vente

En passant commande sur le Site, le Client atteste être une personne physique majeure agissant pour ses besoins personnels. Cette condition est non applicable aux clients « professionnels », c'est-à-dire les clients ayant déclaré être des professionnels au moment de la création de leur compte en cochant « Je suis professionnel »).

Il certifie avoir la capacité de contracter, et reconnaît avoir préalablement pris connaissance et accepter sans réserve :

• les conditions générales de vente applicables à son achat au moment de la passation de la commande ;

• les conditions particulières de vente qui sont celles énoncées sur l'écran (prix, conditions et modalités figurant notamment dans les fiches produits, bon de commande,..) ;

• toutes les informations et renseignements visés à l'article L.121-19 du Code de la consommation.

3/ Produits

3.1 Fiches et emballages des produits

Chaque produit proposé sur le Site («ci-après le(s) « Produit(s) » ») fait l'objet d'une fiche accessible sur le Site. Cette fiche présente les caractéristiques essentielles du Produit conformément à l'article L.111-1 du Code de la consommation.

Malgré toute l'attention portée aux informations indiquées sur la fiche du Produit, avant toute consommation, le Client devra se reporter aux informations complètes figurant sur l'emballage du Produit pour bénéficier de toutes les informations relatives au Produit, notamment en cas d'intolérance alimentaire. L'emballage fournit notamment des informations relatives aux conditions dans lesquelles le Produit doit être stocké et utilisé, conditions que le Client s'engage à respecter.

A l'issue du contrôle desdites informations figurant sur la fiche et sur l'emballage du Produit, le Client est ainsi libre d'annuler, partiellement ou totalement, sa commande. Pour être prise en compte, cette annulation devra être demandée par le Client directement au livreur (ou au point de retrait) YAMIZ avant tout règlement de la commande. Dans le cas où la commande a déjà été livrée et encaissée, le Client pourra réclamer le remboursement du produit en se reportant à la rubrique « nous contacter » du site internet www.yamiz.fr

La liste et les caractéristiques des Produits proposés sont susceptibles d'être modifiés et adaptés à tout moment.

3.2 Photographies

Les photographies des Produits ne sont qu'indicatives et n'engagent pas la responsabilité de YAMIZ.

3.3 Produits vendus au poids

Certains Produits (fruits et légumes, volaille, viande, poisson et charcuterie) sont vendus au poids. Le prix indiqué sur le Site est donné pour un poids moyen estimatif. Le prix facturé pourra être différent car il correspondra au poids effectif du Produit livré.

3.4 Provenance / Origine

Lorsque les Produits commandés mentionnent une provenance, celle-ci est donnée à titre indicatif et prioritaire, sous réserve de la législation applicable. En cas d'indisponibilité du Produit de la provenance indiquée, YAMIZ se réserve la faculté de substituer un Produit d'une autre origine de qualité équivalente.

Le Client pourra refuser le Produit ne correspondant pas à sa commande initiale en termes de provenance en précisant le motif de ce refus sur le récépissé de commande. Dans ce cas, YAMIZ procèdera au remboursement du Produit concerné.

3.5 Allergènes

Les allergènes à déclaration obligatoire sont mis en évidence par une impression qui les distingue clairement du reste de la liste d'ingrédients ; par exemple, pour les Produits surgelés à marque YAMIZ, ils sont soulignés au sein des listes d'ingrédients des Produits concernés.

3.6 Disponibilités

Les offres présentées sur le Site sont valables dans la limite des stocks disponibles. Le Client est informé des indisponibilités au moment de sa passation de commande.

Toutefois, si tout ou partie des Produits commandés est ultérieurement indisponible, YAMIZ en informe le Client à la livraison, les Produits manquants n'étant pas facturés.

3.7 Alcool

Conformément aux dispositions du Code de la santé publique, l'achat de boissons alcooliques est strictement réservé aux personnes majeures, ce que le Client accepte expressément.

Le Client étant une personne majeure, la vente de boissons alcooliques à son égard est autorisée.

Le transport des alcools est soumis à la législation en vigueur.

4/ Commandes

4.1 Création d'un compte client nécessitant identifiant et d'un mot de passe

Pour passer commande, le Client doit préalablement procéder à la création de son compte client personnel en suivant les instructions sur le Site.

Chaque Client est limité à la création d'un seul compte client pour une même identité ou pour un même foyer, ce que le Client s'engage à respecter.

Le Client accède ensuite à son Compte Client au moyen de son identifiant (adresse e-mail utilisée lors de la création du compte) et d'un mot de passe.

Le Client est seul responsable de tout usage qui pourrait être fait de ses identifiant et mot de passe, et est seul garant de leur confidentialité. En conséquence, YAMIZ ne pourra en aucun cas être tenu responsable de toute perte ou tout dommage résultant d'une utilisation par un tiers non autorisé.

Le Client est seul responsable des informations qu'il communique, ainsi que des conséquences pouvant résulter d'une éventuelle erreur ou inexactitude. YAMIZ n'encourt aucune responsabilité de quelque nature que ce soit à cet égard.

Le Client est informé qu'à défaut de paiement des sommes dues au titre d'une quelconque commande, YAMIZ peut suspendre de plein droit et immédiatement la validité de ses identifiants.

Pour chaque première commande passée sur www.yamiz.fr, l'internaute à la possibilité de saisir un code promo "associé", afin de bénéficier directement lors de la prise de commande, d'une remise de 10 € T.T.C à partir de 45 € d'achat T.T.C. Cette offre est valable une seule fois par foyer et est non cumulable avec toute autre offre en cours. Le code a à saisir pour bénéficier de l'offre est soit le code "DECOUVERTE".

4.2 Modalités de passation de la commande

Afin de réaliser la commande, le Client devra obligatoirement suivre les étapes suivantes :

1. Composer l'adresse du Site ;

2. Suivre les instructions du Site et en particulier, se connecter avec son identifiant et son mot de passe ;

3. Sélectionner les Produits souhaités ;

4. Vérifier le récapitulatif de la commande et, le cas échéant, corriger les erreurs ;

5. Choisir son adresse de livraison, le jour et créneau de livraison ;

6. Ajouter le cas échéant, le code promotionnel (un seul code est possible) ;

8. Valider la commande, impliquant qu'il accepte les présentes conditions générales de vente, ainsi que le prix total TTC ;

YAMIZ se réserve le droit d'annuler la commande en cas d'erreur ou d'omission dans la saisie des données obligatoires.

4.4 Confirmation de la commande

Le Client reçoit par voie électronique une confirmation de commande comprenant le récapitulatif de sa commande. La commande ne devient ferme et définitive qu'après l'envoi, par YAMIZ, de ce courriel de confirmation. YAMIZ recommande au Client de conserver l'email de confirmation de commande ou de l'imprimer.

YAMIZ se réserve la possibilité de ne pas accepter une commande, notamment en cas de problème lié à l'approvisionnement, à la livraison, à l'anormalité de la commande, ou en cas de litige avec le Client (défaut de paiement d'une commande antérieure ou autre).

5/ Livraison

Les lieux, dates et horaires de livraison sont sélectionnés par le Client lors de la validation de sa commande, parmi les différents créneaux proposés par YAMIZ.

Le Client choisit entre la livraison à domicile ou en point retrait (si disponible), étant précisé que la livraison hors domicile et hors point retrait est totalement impossible (exemple : dans la rue).

YAMIZ pourra, le cas échéant, faire part au Client ayant passé une commande en ligne de la rupture d'un ou plusieurs Produits, d'un décalage dans le délai de livraison ou de toute autre information concernant la commande.

5.1 Zone de livraison

La livraison à domicile et/ou en point retrait (si disponible), suite à une commande passée par le canal Web, est assurée exclusivement en France métropolitaine (hors Corse et certaines îles bretonnes, si le code postal renseigné par l'utilisateur sur le Site est exclu en livraison, le Site ne proposera aucun créneau de livraison pour ce code) et à Monaco.

Monaco.
5.2 Frais de livraison

La livraison des commandes est gratuite à partir de 200€ d'achat TTC. Pour les commandes d'un montant inférieur, les frais de livraison sont de 0,50€ TTC / KM.

5.3 Délais de livraison

Livraison à domicile :

La livraison s'effectue du lundi au samedi dans les délais ci-après, commençant à courir à compter de la confirmation de la commande par YAMIZ:

• maximum de 30 jours ouvrables ;

• minimum de 48 heures. Ce délai minimum peut être supérieur selon la zone géographique considérée.

En cas d'impossibilité pour YAMIZ de livrer dans le délai prévu, hors cas de force majeure, YAMIZ en informera au plus tôt le Client et pourra convenir avec lui d'une nouvelle date de livraison qui devra intervenir dans les sept (7) jours ouvrables suivant la date de livraison initiale, sauf si le Client souhaite résoudre immédiatement le Contrat dans les conditions visées à l'article L 216-2 du Code de la consommation.

En cas de non-respect de cette seconde date de livraison, le client pourra, à son choix, soit solliciter la résolution de la vente dans les conditions prévues à l'article L.216-2 du Code de la consommation, soit maintenir sa commande.

YAMIZ recycle les cartons de livraison de la commande, ceux-ci pouvant être récupérables par le livreur.

6/ Réception des Produits – Défaut de réception - Réclamation

6.1 Réception

Le Client s'engage à réceptionner les Produits à l'adresse de livraison qu'il a indiquée dans la commande et aux dates et heures convenues avec YAMIZ.

A défaut, YAMIZ procèdera à l'annulation de la commande.

La date de livraison correspond à la date et à l'heure de remise ou de première présentation effective à l'adresse de livraison indiquée par le Client (domicile ou point de retrait).

Le transfert des risques intervient à la livraison.

Une pièce d'identité pourra être exigée lors de la livraison.

En cas de refus de présentation ou de documents ne correspondant pas aux informations communiquées lors de la commande, aucune remise des Produits ne pourra être honorée et YAMIZ se réserve le droit d'annuler la commande.

A la livraison, le Client confirme la bonne réception de la commande, de son contenu, du nombre de Produits indiqué sur le bon de livraison ainsi que du bon état des Produits en signant le récépissé de livraison sur lequel il pourra porter ses réserves éventuelles.

A défaut de signature du récépissé, la commande est réputée livrée dans sa totalité.

Le paiement à la livraison par remise de chèque, paiement par carte, espèce ou titre restaurant vaut acceptation sans réserve de la livraison des Produits.

6.2 Défaut de réception

Lorsque la remise au Client n'a pas pu être possible de son fait (absence, informations de livraisons erronées, etc.), un avis de passage est laissé, avec le numéro de téléphone à appeler pour convenir d'une nouvelle date de livraison.

La nouvelle livraison interviendra aux frais du Client et sera facturée aux frais réels engagés par YAMIZ qui en justifiera par la présentation de la facture correspondante.

Dans l'hypothèse où YAMIZ ne parviendrait pas à entrer en contact avec le Client dans un délai de sept (7) jours ouvrables suivant la date de livraison initialement prévue, en cas d'absence à la nouvelle date convenue, ou encore en cas de refus de facturation des frais de livraison, le montant de la commande, frais de livraison compris le cas échéant, seront dus par le Client.

6.3 Réclamation

Le Client est invité à vérifier les Produits à la livraison. Dans le cas où des Produits seraient manquants ou endommagés, il appartient au Client de formuler toutes observations ou réserves au livreur ou transporteur et, le cas échéant, de refuser le Produit en précisant le motif sur le ticket remis par le livreur.

7/ Prix et paiement

7.1 Prix

Le prix des Produits est celui figurant sur le Site à la date de la commande.

Le prix est indiqué sur le Site en euro TTC et inclut la TVA applicable au jour de la commande. Par exception, pour les clients « professionnels », le prix des Produits figurant sur le Site à la date de la commande est affiché hors taxe.

Le cas échéant, les frais de livraison sont indiqués sur le récapitulatif de la commande.

Le prix définitif est le montant de la commande que le Client aura validé en procédant à son paiement en ligne (c'est-à-dire un paiement du montant de la commande en ligne par Carte Bancaire/CB via notre partenaire qui assure la sécurité du paiement en ligne ou via le système Paypal, ci-après « Paiement en ligne »).

Ce prix pourra toutefois varier :

• en cas d'ajout, sur demande du Client, d'un ou de plusieurs Produits par le livreur ;

• en cas d'indisponibilité d'un ou de plusieurs Produits commandé(s) ;

• s'il y a Paiement en ligne, en cas de commande de Produits à poids variables, conformément à l'article 3.3 des présentes (dans ce cas, en fonction du poids effectif du Produit livré, si le montant de la commande livrée est supérieur au montant de la commande initiale, une nouvelle demande d'autorisation est effectuée ainsi qu'un nouvel envoi en banque pour le complément restant à payer. Si le montant de la commande livrée est inférieur au montant de la commande initiale, c'est ce nouveau montant qui est envoyé en banque).

7.2 Paiement

Le Client a la possibilité de payer sur place ou en ligne sa commande, au moment du passage de cette dernière. Le paiement à la livraison est proscrit, ce que le Client accepte expressément.

7.2.1 Paiement par carte bancaire

Conformément à l'article L. 132-2 du Code monétaire et financier, l'engagement de payer donné au moyen d'une carte de paiement est irrévocable. En communiquant les informations relatives à sa carte bancaire, le Client autorise YAMIZ à débiter sa carte bancaire du montant correspondant au prix de la commande.

À cette fin, le Client confirme qu'il est le titulaire de la carte bancaire à débiter et que le nom figurant sur la carte bancaire est effectivement le sien. Le Client communique le numéro de la carte bancaire à débiter, la date d'expiration de sa carte bancaire ainsi que le cryptogramme visuel.

Les cartes suivantes ne sont pas acceptées:

{' '} - Cartes bancaires AMEX{' '}

7.2.2 Paiement Paypal

Il est possible de régler en ligne via le service proposé par Paypal et sa plateforme de paiement en ligne (ci-après la « Plateforme »).

La première connexion à la Plateforme nécessite que le Client renseigne son identifiant et son mot de passe « Paypal » afin de permettre à Paypal d'autoriser le paiement en ligne via la Plateforme.

Sauf désaccord du Client enregistré par Paypal, tout paiement en ligne via Paypal, ultérieur à celui réalisé pour la première commande, sera réalisé automatiquement sans qu'il soit nécessaire pour le Client de saisir à nouveau ses éléments de connexion à la Plateforme.

YAMIZ étant extérieure à la relation contractuelle intervenant entre le Client et Paypal au titre du paiement en ligne, YAMIZ se dégage de toute responsabilité ou tout litige qui pourrait naître en lien avec l'exécution ou la non-exécution de ce service.

YAMIZ invite à ce titre le Client à prendre connaissance des conditions d'utilisation des services Paypal.

7.2.3 Dispositions générales sur le paiement

Les Produits demeurent la propriété de YAMIZ jusqu'au complet paiement du prix.

Le paiement, qu'il s'agisse d'un Paiement en ligne ou à la livraison, ne sera débité ou remis à l'encaissement qu'au moment de la livraison, après vérification avec le livreur de la commande Produit par Produit.

Si le délai entre la commande et la livraison est supérieur à 6 jours ouvrables, alors YAMIZ se réserve le droit de dupliquer la demande d'autorisation émise le jour de la commande. Si cette nouvelle demande échouait, le Paiement en ligne enregistré lors de la commande sera caduc et il sera demandé au client de payer avec un autre moyen de paiement (autre carte bancaire, chèque ou espèce) l'intégralité du montant de la commande.

8/ Droit de rétractation

Conformément à l'article L.221-28 du Code de la consommation, les achats de Produits surgelés et frais ne sont pas soumis au droit de rétractation. Après livraison, ils ne seront ni repris, ni échangés, ni remboursés. Pour les autres Produits, le Client dispose d'un délai de rétractation de quatorze (14) jours à compter de leur réception.

Pour les Produits pouvant faire l'objet du droit de rétractation, le Client peut exercer son droit en complétant le formulaire disponible sur le Site sous le lien suivant ou en contactant le Service clients YAMIZ via le numéro 0474559194.

Toute demande incomplète ne pourra pas être prise en charge.

Le Client dispose de 14 jours à compter de l'envoi du formulaire pour retourner à YAMIZ, à ses frais, les articles dans leur état d'origine et complets, par voie postale normalisée à l'adresse suivante : YAMIZ – Service Administratif - 5 rue des crêts 01000 Bourg en Bresse.

YAMIZ remboursera ou échangera (sous réserve de disponibilité) dans un délai de 14 jours à compter de la date de restitution des Produits, sous réserve que les Produits retournés soient dans leur emballage d'origine, complets, en parfait état et accompagnés du formulaire de rétractation et du récapitulatif de votre commande (disponible sur le Site à la rubrique « Mes Commandes »).

Pour soumettre une remarque sur un Produit, le client pourra remplir le formulaire accessible via ce lien : https://www.yamiz.fr/nous-contacter

9/ Garantie – Exclusion de garantie

9.1 Garantie

Le Client bénéficie de la garantie légale relative aux défauts cachés au sens des Articles 1641 et suivants du Code civil ainsi que de la garantie relative aux défauts de conformité au sens des Articles L 217-1 et suivants du Code de la consommation.

Lorsque le Client agit en garantie légale de conformité :

{' '} -Il bénéficie d'un délai de 2 ans à compter de la délivrance du produit pour agir ;

{' '} -Il peut choisir entre la réparation ou le remplacement du bien, sous réserve de certaines conditions de coût prévues par l'article L.217-9 du code de la consommation ;

{' '} -Il est dispensé de rapporter la preuve de l'existence du défaut de conformité durant les 24 mois suivant la délivrance du bien.

La garantie légale de conformité s'applique indépendamment de la garantie commerciale éventuellement consentie.

Le Client peut décider de mettre en œuvre la garantie contre les défauts cachés de la chose vendue au sens de l'article 1641 du code civil ; dans ce cas, le Client peut choisir entre la résolution de la vente ou une réduction du prix de vente conformément à l'article 1644 du code civil.

Conformément à l'article L.217-15 du Code de la consommation, sont reproduits ci-après les dispositions des articles L.217-4, L.217-5, L.217-12 et L.217-16 du Code de la consommation, ainsi que les articles 1641 et 1648 (alinéa premier) du Code civil :

Article L.217-4

Le vendeur livre un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance.

Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité.

Article L.217-5

Le bien est conforme au contrat :

1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :

{' '} - s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ;

{' '} - s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l'étiquetage ;

2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté.

Article L.217-12

L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien.

Article L.217-16

Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été consentie lors de l'acquisition ou de la réparation d'un bien meuble, une remise en état couverte par la garantie, toute période d'immobilisation d'au moins sept jours vient s'ajouter à la durée de la garantie qui restait à courir.

Article L.217-16

Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été consentie lors de l'acquisition ou de la réparation d'un bien meuble, une remise en état couverte par la garantie, toute période d'immobilisation d'au moins sept jours vient s'ajouter à la durée de la garantie qui restait à courir.

Cette période court à compter de la demande d'intervention de l'acheteur ou de la mise à disposition pour réparation du bien en cause, si cette mise à disposition est postérieure à la demande d'intervention.

Article 1641

Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent impropre à l'usage auquel on la destine, ou qui diminuent tellement cet usage que l'acheteur ne l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait connus.

Article 1648

L'action résultant des vices rédhibitoires doit être intentée par l'acquéreur dans un délai de deux ans à compter de la découverte du vice.

Dans le cas prévu par l'article 1642-1, l'action doit être introduite, à peine de forclusion, dans l'année qui suit la date à laquelle le vendeur peut être déchargé des vices ou des défauts de conformité apparents.

Mise en jeu de la garantie

La garantie de YAMIZ est limitée au remplacement ou au remboursement des Produits non conformes ou affectés d'un vice, ainsi que des éventuels frais de livraison.

Afin de faire valoir ses droits, le client devra signaler l'existence d'un vice ou d'un défaut de conformité dans un délai maximum de 48 heures de leur découverte soit par e-mail dans la rubrique "Contactez-nous - Mes commandes", soit en appelant le Service clients YAMIZ via le numéro 0474559194 )(modalités de contact précisées article « Service Information Consommateurs / Service clients ») des présentes).

9.2 Exclusion de garantie

Le Client est seul responsable du choix des Produits, de leur conservation et de leur utilisation.

Toute garantie est exclue en cas de mauvaise utilisation ou de mauvaise conservation.

10/ Responsabilité

YAMIZ, dans toutes les étapes du processus de vente en ligne, n'est tenue que par une obligation de moyen.

YAMIZ sera déchargée de la livraison prévue en cas d'événement constitutif de force majeure ou indépendant de sa volonté.

Les informations énoncées par le Client, lors de la prise de commande, engagent celui-ci.

YAMIZ ne pourra en aucun cas être tenue pour responsable :

• en cas de fausses informations ou d'erreur de saisie de ses coordonnées par le Client ;

• en cas de mauvaise utilisation de ses identifiants ou du mode opératoire de passation de commande par le Client ;

• en cas d'erreur manifeste d'affichage d'un prix dérisoire ;

• en cas d'erreurs techniques ou matérielles, ou de tout dommage direct ou indirect tel que notamment perte de données, intrusion, virus, rupture du service, autres problèmes involontaires ou cas de force majeure résultant tant de l'utilisation du réseau Internet que du Site ;

• en cas d'interruption du Site.

• en cas de non-respect, par le Client, des conditions de stockage et d'utilisation du Produit avant consommation.

• en cas de diffusion d'un code promo avec une erreur de paramétrage (annulation de la commande).

En tout état de cause, la responsabilité de YAMIZ est limitée au remboursement des sommes versées pour l'acquisition des Produits et des frais de livraison éventuels.

11/ Documents probatoires

La fourniture en ligne du numéro de carte bancaire et la validation de la commande ou la fourniture en ligne de ses données de connexion au service de paiement en ligne « Paypal », par le Client, valent preuve de la commande et entrainent l'exigibilité du paiement correspondant.

Pourront également constituer une preuve juridique des communications : la commande, le paiement du client et tous documents issus de l'archivage des bons de commande et des factures dans les systèmes informatiques de YAMIZ.

12/ Service Information Consommateurs / Service clients

Tout client peut obtenir des renseignements – ingrédients, valeurs nutritionnelles, etc. – auprès de nos conseillers(ères) lors de la prise de commande. Pour toute difficulté, le client peut :

{' '} - adresser un e-mail via le formulaire de contact accessible sur la page d'accueil du Site : https://www.yamiz.fr/nous-contacter ;

{' '} - envoyer un courrier à : YAMIZ - Service administratif - 5 rue des crêts 01000 Bourg en Bresse

{' '} - contacter le Service clients YAMIZ via le numéro 0474559194 (appel gratuit depuis un poste fixe) (disponible du lundi au vendredi de 9h00 à 12h00 et le samedi de 9h00 à 12h00, étant précisé que ces horaires sont susceptible de modifications, notamment pendant les périodes de congés d'été).

13/ « Données Personnelles »

13.1

Des données personnelles vous concernant (ci-après les « Données ») sont collectées et traitées par YAMIZ, responsable de ce traitement, notamment pour traiter et exécuter votre commande de Produits. Plusieurs informations sur la manière dont nous traitons ces Données sont fournies au sein des Mentions Légales du Site, de la « Politique Données personnelles - YAMIZ » et de notre « Politique Cookies » (documents disponibles sur notre Site https://www.yamiz.fr/content/3-conditions-generales-de-vente).

13.2.

Le Client pourra également, au moment du paiement de sa commande :

{' '} - enregistrer une ou plusieurs cartes bancaires, afin de pouvoir valider en un clic le Paiement en ligne de toutes ses commandes Internet futures (sans avoir à saisir de nouveau ses données de carte bancaire), en cochant la case « sauvegarder ma carte » ;

{' '} - supprimer toute carte bancaire qu'il aura préalablement enregistrée.

Les données relatives au paiement en ligne via Paypal sont conservées par Paypal.

Pour la bonne exécution de votre commande et l'amélioration continue de nos services, nous vous informons que les appels passés à notre Service clients 0474559194, les appels passés par notre Service clients, les appels passés notre équipe de télévente et par nos services de téléprospection sont enregistrés. Vous disposez d'un droit d'accès à l'enregistrement de vos appels sur simple demande adressée à notre service clients (coordonnées ci-avant).

14/ Cookies

Lors de l'utilisation du Site, un cookie stocke des informations relatives à la navigation du Client sur le Site.

Pour plus d'informations sur les cookies stockés, vous pouvez consulter notre Politique Cookies accessible ici : https://www.yamiz.fr/p-cnil

Le Client peut s'opposer à l'enregistrement des cookies et en désactiver l'utilisation en paramétrant son navigateur Internet de manière appropriée, étant précisé qu'une telle désactivation peut restreindre l'utilisation du Site.

15/ Propriété intellectuelle

Le contenu du Site Internet créé par YAMIZ est la propriété exclusive de YAMIZ et de ses sous-traitants, qui sont titulaires de l'ensemble des droits de propriété intellectuelle s'y rapportant.

Le Client s'interdit de copier, reproduire ou télécharger tout ou partie de son contenu, sauf autorisation préalable, écrite et expresse de YAMIZ.

Toute copie, reproduction ou téléchargement totale ou partielle du contenu du Site internet créé par YAMIZ est susceptible de constituer un délit de contrefaçon.

Toutes les marques et éléments protégés par un droit de propriété intellectuelle ou industrielle attachés aux Produits sont la propriété exclusive ou sous licence de YAMIZ. Par les présentes, le Client n'acquiert aucun droit sur les marques et les éléments protégés par un droit de propriété intellectuelle ou industrielle attachés aux Produits.

16/ Revente des Produits

La revente en l'état des Produits de la marque YAMIZ, des Produits des marques appartenant à YAMIZ et des Produits bio et naturels commercialisés sur le Site est interdite.

17/ Droit applicable – Règlement des Litiges - Médiation

Les présentes conditions générales de vente sont soumises au droit français.

A défaut de règlement amiable, les litiges relatifs aux commandes passées sur le Site sont de la compétence exclusive des tribunaux français, nonobstant pluralité de défendeurs ou appel en garantie.

Conformément au Code de la Consommation, en cas de litige, le Client peut recourir gratuitement au service de médiation Médicys qui peut être joint par voie électronique par le biais de son site https://app.medicys.fr/ ou par voie postale au 73, Boulevard de Clichy, 75009- Paris ».

Le Client pourra ensuite désigner le médiateur de son choix au sein de la liste des médiateurs inscrits à Médicys. Cette liste est disponible ici.

Conformément à l'article L.616-2 du Code de la consommation, YAMIZ informe le Client de l'existence de la plateforme mise en ligne par la Commission européenne qui a pour objet de recueillir les éventuelles réclamations issues d'un achat en ligne des consommateurs européens et de transmettre ensuite les cas reçus aux médiateurs nationaux compétents. Ce lien est accessible à l'adresse suivante :

https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR

Dispositions particulières concernant le Client Professionnel

Si le Client a passé commande dans le cadre de son activité professionnelle, toutes les contestations se rapportant à ladite commande, et qui ne pourraient être réglées à l'amiable, seront soumises au Tribunal de Commerce de Bourg en Bresse, qui sera seul compétent.

Dernière mise à jour le 02/09/2020
    </div>
  )
}

export default Cgus