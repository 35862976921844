import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Modal, ModalBody, Button } from "reactstrap";

import { useSelector } from "react-redux";
import { Api } from "api";
import { logoutSuccess } from "actions";
import { currentUserSelect } from "../../modules/App/selector";
import AppInput from "components/AppInput";
import { useForgotPass } from "./hooks/useForgotPass";
const ForgotPass = (props) => {
  const dispatch = useDispatch();
  const { inputSearch } = useSelector(currentUserSelect) || {};
  const [username, setUsername] = useState("");

  const [{ fetching, error }, forgotPassword] = useForgotPass();

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = { username };
    forgotPassword(payload);
  };

  return (
    <Modal
      size="lg"
      isOpen={props.isOpen}
      toggle={() => {
        if (!fetching) {
          props.onCloseModal();
        }
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title text-bold">
          Demander un nouveau mot de passe
        </h5>
        <button
          onClick={(e) => {
            if (!fetching) {
              props.onCloseModal();
            }
          }}
          aria-label="Close"
          className="close"
          type="button"
        >
          <img
            src={require("assets/img/iconClose.png")}
            className=""
            width="16px"
            alt=">"
          />
        </button>
      </div>
      <ModalBody className="forgot">
        <div >
          <AppInput
            label="Email"
            className="inputSearch"
            required
            id="login-pwd"
            name="pwd"
            placeholder=""
            type={"text"}
            value={username}
            onChange={(t) => setUsername(t)}
          />
        </div>
        {error && (
        <h5 className="error">{error.message || "Erreur de connexion"}</h5>
      )}
      </ModalBody>
     
      <div className="modal-footer logout">
        <Button
          disabled={fetching}
          className="BtnSignIn text-bold"
          color="default"
          type="button"
          onClick={(e) => onSubmit(e)}
        >
          Envoyer
        </Button>
      </div>
      <div
        onClick={() => {
          props.onShowSignIn();
          props.onCloseModal();
        }}
        className="TextSignUp text-demi mt-4 mb-4"
      >
        Je déjà un Compte
      </div>
    </Modal>
  );
};

export default ForgotPass;
