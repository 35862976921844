export function catProductsUrl(skip, q, Rayons, Feature) {
  return Rayons && Feature
    ? `/products?$top=12&$skip=${skip}&$q=${
        q === undefined ? "" : q
      }&$filter={"category":${JSON.stringify(
        Rayons
      )} , "features":${JSON.stringify(Feature)}}&$expand=category`
    : Rayons
    ? `/products?$top=12&$skip=${skip}&$q=${
        q === undefined ? "" : q
      }&$filter={"category":${JSON.stringify(Rayons)}}&$expand=category`
    : Feature
    ? `/products?$top=12&$skip=${skip}&$q=${
        q === undefined ? "" : q
      }&$filter={"features":${JSON.stringify(Feature)}}&$expand=category`
    : `/products?$top=12&$skip=${skip}&$q=${
        q === undefined ? "" : q
      }&$expand=category`;
}
