// import front-end config
// import { REHYDRATE } from "redux-persist/es/constants";
import {
  GET_CURRENT_USER_R,
  GET_CURRENT_USER_F,
  SET_CURRENT_USER,
  LOGOUT_S,
  SET_SIDEBAR_OPEN,
  SET_ACTIVE_TAB,
  SET_CURRENT_SEARCH,
  SET_CURRENT_INFO_SEARCH,
  SET_SEARCH,
  SET_RAYONS,
  SET_FEATURE,
  SET_SIGNIN,
  SET_FORGOTPASS,
  SET_SIGNUP,
  SET_BASKET,
  SET_ORDER,
  SET_SIGNUP_SU,
  SET_EMAIL,
  SET_ORDER_SU,
} from "../actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  currentUser: null,
  fetchingUser: false,
  sidebarIsOpen: true,
  color: "primary",
  barSearch: {},
  showSingIn: false,
  showSingUp: false,
  showSingUpSuccess: false,
  showForgot: false,
  showLogout: false,
  showBasket: false,
  showOrder: false,
  showOrderSuccess:false,
  Rayons: "",
  Feature:"",
  inputSearch: "",
  email:"",
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    // case REHYDRATE:
    //   if (action.payload) {
    //     return {
    //       ...state,
    //       sidebarIsOpen: true,
    //       color: action.payload.app?.color || "primary",
    //     };
    //   }
    // return state;

    case GET_CURRENT_USER_R:
      return { ...state, fetchingUser: true };

    case GET_CURRENT_USER_F:
      return { ...state, fetchingUser: false };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        fetchingUser: false,
        isGuest: false,
      };

      
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_CURRENT_SEARCH:
      return {
        ...state,
        barSearch: action.payload,
      };

    case SET_SEARCH:
      return {
        ...state,
        inputSearch: action.payload,
      };
      
    case SET_RAYONS:
      return {
        ...state,
        Rayons: action.payload,
      };
      case SET_FEATURE:
        return {
          ...state,
          Feature: action.payload,
        };
    case SET_ORDER_SU:
      return {
        ...state,
        showOrderSuccess: action.payload,
      };
      case SET_SIGNIN:
        return {
          ...state,
          showSingIn: action.payload,
        };
    case SET_SIGNUP:
      return {
        ...state,
        showSingUp: action.payload,
      };
    case SET_SIGNUP_SU:
      return {
        ...state,
        showSingUpSuccess: action.payload,
      };

    case SET_FORGOTPASS:
      return {
        ...state,
        showForgot: action.payload,
      };
    case SET_BASKET:
      return {
        ...state,
        showBasket: action.payload,
      };
    case SET_ORDER:
      return {
        ...state,
        showOrder: action.payload,
      };

    case SET_SIDEBAR_OPEN:
      return { ...state, sidebarIsOpen: action.payload };

    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };

    case LOGOUT_S:
      return { ...state, currentUser: null };

    default:
      return state;
  }
}
