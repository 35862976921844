import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { setCurrentUser } from "actions";
import { bindActionCreators } from "redux";
import * as actions from 'actions';

// endpoints
import { loginUrl } from "api/endpoints";

export function useSignIn() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  async function signIn(payload) {
    try {
      setFetching(true);
      const data = await Api().post(loginUrl(), payload);
      dispatch(setCurrentUser(data));
      actionsDispatch.setSignIn(false)
      // dispatch(push(`/home/list`));
    } catch (error) {
      reportError("signin error", error);
      setError(error);
    } finally {
      setFetching(false);
    }
  }

  return [{ fetching, error }, signIn];
}
