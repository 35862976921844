import React, { useState, useMemo, useEffect } from "react";
import Slider from "components/Slider/slider";
import Service from "components/Service/service";
import Product from "components/Product/product";
import { Row, Col, Spinner, Button } from "reactstrap";
import apiConfig from "config/apiConfig";
import { catProductsUrl } from "../../api/endpoints";
import Api from "api/api";
import { currentState } from "../../selector";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions";
import { push } from "connected-react-router";
const Details = (props) => {
  const { BASE_URL } = apiConfig;
  const { current } = props.details;
  const dispatch = useDispatch();
  const { inputSearch, Rayons } = useSelector(currentState) || {};
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <div>
      {Rayons.name && (
        <>
          <div className="headerRayons text-bold mb-2">{Rayons.name}</div>
          <div className="breadcrumbRayons  text-Regular ml-4 mb-4">
            <span
              onClick={() => {
                dispatch(push(`/home/list`));
                actionsDispatch.setRayons({});
                 actionsDispatch.setFeature({});
              }}
              style={{ cursor: "pointer" }}
            >{`Accueil${"  "}>`}</span>
            {`   Rayons${"  "}>  ${Rayons.name}${"  "}>  ${current.name} `}
          </div>
        </>
      )}

      <div className="app-main mb-4">
        <Row className="detailProduct mb-4">
          <Col xs={12} md={4} className="image">
            <img
              width="400px"
              height="250px"
              alt="+"
              src={`${BASE_URL}${current.imageUrl}`}
            />
          </Col>
          <Col xs={12} md={8} className="detail">
            <div className="title text-bold">{current.name}</div>
            <div className="description text-regular">
              {current.shortDescription}
            </div>
            <div className="footerDetail">
              <div className="priceTtc text-bold">
                {current.priceETtc} € TTC
              </div>
              <div className="priceHt text-regular">{current.priceE} € HT</div>

              <div className="btnBasket mt-2">
                <div className="text text-demi">Ajouter au panier</div>
                <div className="ml-4">
                  <img
                    width="25px"
                    height="25px"
                    alt="+"
                    src={require("assets/img/iconAddBasket.png")}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-bold mt-4">Description</div>
            <div>{current.longDescription}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-bold mt-4">Ingrédients</div>
            <div>
              Préparez en quelques minutes un repas typiquement breton grâce à
              nos 6 galettes de blé noir au sel de Guérande surgelées Toupargel.
              Cette recette traditionnelle à base de farine origine France, sans
              conservateur, sans colorant ni arôme artificiel, d'un diamètre
              généreux de 28 cm, vous permettra de confectionner de savoureuses
              galettes. De l'emblématique galette saucisse à la complète, ces
              galettes de blé noir au sel de Guérande surgelées Toupargel se
              plient à toutes vos envies !
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Details;
