import React from "react";
import classNames from "classnames";
import { FormGroup, Input, Label } from "reactstrap";

export default function AppInput({
  label,
  required,
  hasError,
  name,
  placeholder,
  type,
  value,
  onChange,
  pattern,
  disabled,
  onKeyUp,
  className,
  ref,
  ...props
}) {
  return (
    <>
      {!disabled ? (
        <FormGroup>
          <Label className="text-black" for={name}>
            {label}
            {required && <span>*</span>}
          </Label>
          <Input
            ref={ref}
            className={
              className
                ? className
                : classNames("custom-input", {
                    // matriculeLast: props.className === 'matricule-last',
                    error: hasError,
                  })
            }
            disabled={disabled}
            name={name || `_input`}
            placeholder={placeholder}
            type={type || "text"}
            value={value}
            onKeyUp={(e) => {
              // if (event.keyCode === 13 &&  === "INPUT") {
              if (e.key === "Enter" && props.handleEnter) {
                props.handleEnter();
              }
            }}
            onChange={(e) => {
              onChange(e.currentTarget.value);
            }}
            autoComplete="off"
            step=".01"
            pattern={pattern}
            {...props}
          />
          {props.leftaddon && props.leftaddon}
          <div>
            {props.description !== undefined && (
              <span className="app-input-desc">{props.description}</span>
            )}
          </div>
        </FormGroup>
      ) : (
        <FormGroup>
          <Label className="text-black" for={name}>
            {label}
            {required && <span>*</span>}
          </Label>
          <Input
            className={classNames("custom-input-disabled", {
              // matriculeLast: props.className === 'matricule-last',
              error: hasError,
            })}
            disabled={disabled}
            name={name || `_input`}
            placeholder={placeholder}
            type={type || "text"}
            onKeyUp={(e) => {
              if (e.key === "Enter" && props.handleenter) {
                props.handleenter();
              }
            }}
            value={value}
            autocomplete="off"
            step=".01"
            pattern={pattern}
            {...props}
          />
          {props.leftaddon && props.leftaddon}
          <div>
            {props.description !== undefined && (
              <span className="app-input-desc">{props.description}</span>
            )}
          </div>
        </FormGroup>
      )}
    </>
  );
}
