import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';

// helpers
import reportError from 'lib/errorHandler';

// api
import {Api} from 'api';

// actions
import {uploadFiles} from 'actions';
import {deleteOrdersUrl} from '../api/endpoints';
import {bindActionCreators} from 'redux';
import * as actions from '../actions';
export function useDeleteOrders(id) {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  async function deleteOrders() {
    try {
      setFetching(true);
      await Api().delete(deleteOrdersUrl(id));
      setFetching(false);
      // document.location.reload(true);
    } catch (errorFile) {
      reportError('updatecyCleLong error', errorFile);
      setError(errorFile);
      setFetching(false);
    } finally {
      // setFetching(false);
    }
  }
  return [{fetching, error}, deleteOrders];
}
