export const GOOGLE_API_KEY = "AIzaSyBXWOxwBiZNJf_cTQ3NwieZfZhJrH4lPJg";

export const THEME_OPTIONS = [
  { label: "#0074af", value: "primary", text: "Bleu" },
  { label: "#3cc47c", value: "secondary", text: "Vert" },
  { label: "#7F5495", value: "violet", text: "Violet " },
];

export const PAYS = [
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Afrique du Sud", value: "Afrique du Sud" },
  { label: "Albanie", value: "Albanie" },
  { label: "Algérie", value: "Algérie" },
  { label: "Allemagne", value: "Allemagne" },
  { label: "Andorre", value: "Andorre" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarctique", value: "Antarctique" },
  { label: "Antigua-et-Barbuda", value: "Antigua-et-Barbuda" },
  { label: "Antilles néerlandaises", value: "Antilles néerlandaises" },
  { label: "Arabie saoudite", value: "Arabie saoudite" },
  { label: "Argentine", value: "Argentine" },
  { label: "Arménie", value: "Arménie" },
  { label: "Aruba", value: "Aruba" },
  { label: "Australie", value: "Australie" },
  { label: "Autriche", value: "Autriche" },
  { label: "Azerbaïdjan", value: "Azerbaïdjan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahreïn", value: "Bahreïn" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbade", value: "Barbade" },
  { label: "Bélarus", value: "Bélarus" },
  { label: "Belgique", value: "Belgique" },
  { label: "Belize", value: "Belize" },
  { label: "Bénin", value: "Bénin" },
  { label: "Bermudes", value: "Bermudes" },
  { label: "Bhoutan", value: "Bhoutan" },
  { label: "Bolivie", value: "Bolivie" },
  { label: "Bosnie-Herzégovine", value: "Bosnie-Herzégovine" },
  { label: "Botswana", value: "Botswana" },
  { label: "Brésil", value: "Brésil" },
  { label: "Brunéi Darussalam", value: "Brunéi Darussalam" },
  { label: "Bulgarie", value: "Bulgarie" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodge", value: "Cambodge" },
  { label: "Cameroun", value: "Cameroun" },
  { label: "Canada", value: "Canada" },
  { label: "Cap-Vert", value: "Cap-Vert" },
  { label: "Ceuta et Melilla", value: "Ceuta et Melilla" },
  { label: "Chili", value: "Chili" },
  { label: "Chine", value: "Chine" },
  { label: "Chypre", value: "Chypre" },
  { label: "Colombie", value: "Colombie" },
  { label: "Comores", value: "Comores" },
  { label: "Congo-Brazzaville", value: "Congo-Brazzaville" },
  { label: "Corée du Nord", value: "Corée du Nord" },
  { label: "Corée du Sud", value: "Corée du Sud" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Côte d’Ivoire", value: "Côte d’Ivoire" },
  { label: "Croatie", value: "Croatie" },
  { label: "Cuba", value: "Cuba" },
  { label: "Danemark", value: "Danemark" },
  { label: "Diego Garcia", value: "Diego Garcia" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominique", value: "Dominique" },
  { label: "Égypte", value: "Égypte" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Émirats arabes unis", value: "Émirats arabes unis" },
  { label: "Équateur", value: "Équateur" },
  { label: "Érythrée", value: "Érythrée" },
  { label: "Espagne", value: "Espagne" },
  { label: "Estonie", value: "Estonie" },
  { label: "État de la Cité du Vatican", value: "État de la Cité du Vatican" },
  {
    label: "États fédérés de Micronésie",
    value: "États fédérés de Micronésie",
  },
  { label: "États-Unis", value: "États-Unis" },
  { label: "Éthiopie", value: "Éthiopie" },
  { label: "Fidji", value: "Fidji" },
  { label: "Finlande", value: "Finlande" },
  { label: "France", value: "France" },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambie", value: "Gambie" },
  { label: "Géorgie", value: "Géorgie" },
  {
    label: "Géorgie du Sud et les îles Sandwich du Sud",
    value: "Géorgie du Sud et les îles Sandwich du Sud",
  },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Grèce", value: "Grèce" },
  { label: "Grenade", value: "Grenade" },
  { label: "Groenland", value: "Groenland" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guernesey", value: "Guernesey" },
  { label: "Guinée", value: "Guinée" },
  { label: "Guinée équatoriale", value: "Guinée équatoriale" },
  { label: "Guinée-Bissau", value: "Guinée-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Guyane française", value: "Guyane française" },
  { label: "Haïti", value: "Haïti" },
  { label: "Honduras", value: "Honduras" },
  { label: "Hongrie", value: "Hongrie" },
  { label: "Île Bouvet", value: "Île Bouvet" },
  { label: "Île Christmas", value: "Île Christmas" },
  { label: "Île Clipperton", value: "Île Clipperton" },
  { label: "Île de l'Ascension", value: "Île de l'Ascension" },
  { label: "Île de Man", value: "Île de Man" },
  { label: "Île Norfolk", value: "Île Norfolk" },
  { label: "Îles Åland", value: "Îles Åland" },
  { label: "Îles Caïmans", value: "Îles Caïmans" },
  { label: "Îles Canaries", value: "Îles Canaries" },
  { label: "Îles Cocos - Keeling", value: "Îles Cocos - Keeling" },
  { label: "Îles Cook", value: "Îles Cook" },
  { label: "Îles Féroé", value: "Îles Féroé" },
  { label: "Îles Heard et MacDonald", value: "Îles Heard et MacDonald" },
  { label: "Îles Malouines", value: "Îles Malouines" },
  { label: "Îles Mariannes du Nord", value: "Îles Mariannes du Nord" },
  { label: "Îles Marshall", value: "Îles Marshall" },
  {
    label: "Îles Mineures Éloignées des États-Unis",
    value: "Îles Mineures Éloignées des États-Unis",
  },
  { label: "Îles Salomon", value: "Îles Salomon" },
  { label: "Îles Turks et Caïques", value: "Îles Turks et Caïques" },
  { label: "Îles Vierges britanniques", value: "Îles Vierges britanniques" },
  {
    label: "Îles Vierges des États-Unis",
    value: "Îles Vierges des États-Unis",
  },
  { label: "Inde", value: "Inde" },
  { label: "Indonésie", value: "Indonésie" },
  { label: "Irak", value: "Irak" },
  { label: "Iran", value: "Iran" },
  { label: "Irlande", value: "Irlande" },
  { label: "Islande", value: "Islande" },
  { label: "Israël", value: "Israël" },
  { label: "Italie", value: "Italie" },
  { label: "Jamaïque", value: "Jamaïque" },
  { label: "Japon", value: "Japon" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordanie", value: "Jordanie" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kirghizistan", value: "Kirghizistan" },
  { label: "Kiribati", value: "Kiribati" },
  { label: "Koweït", value: "Koweït" },
  { label: "Laos", value: "Laos" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Lettonie", value: "Lettonie" },
  { label: "Liban", value: "Liban" },
  { label: "Libéria", value: "Libéria" },
  { label: "Libye", value: "Libye" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lituanie", value: "Lituanie" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Macédoine", value: "Macédoine" },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malaisie", value: "Malaisie" },
  { label: "Malawi", value: "Malawi" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malte", value: "Malte" },
  { label: "Maroc", value: "Maroc" },
  { label: "Martinique", value: "Martinique" },
  { label: "Maurice", value: "Maurice" },
  { label: "Mauritanie", value: "Mauritanie" },
  { label: "Mayotte", value: "Mayotte" },
  { label: "Mexique", value: "Mexique" },
  { label: "Moldavie", value: "Moldavie" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolie", value: "Mongolie" },
  { label: "Monténégro", value: "Monténégro" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Namibie", value: "Namibie" },
  { label: "Nauru", value: "Nauru" },
  { label: "Népal", value: "Népal" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigéria", value: "Nigéria" },
  { label: "Niue", value: "Niue" },
  { label: "Norvège", value: "Norvège" },
  { label: "Nouvelle-Calédonie", value: "Nouvelle-Calédonie" },
  { label: "Nouvelle-Zélande", value: "Nouvelle-Zélande" },
  { label: "Oman", value: "Oman" },
  { label: "Ouganda", value: "Ouganda" },
  { label: "Ouzbékistan", value: "Ouzbékistan" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palaos", value: "Palaos" },
  { label: "Panama", value: "Panama" },
  { label: "Papouasie-Nouvelle-Guinée", value: "Papouasie-Nouvelle-Guinée" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Pays-Bas", value: "Pays-Bas" },
  { label: "Pérou", value: "Pérou" },
  { label: "Philippines", value: "Philippines" },
  { label: "Pitcairn", value: "Pitcairn" },
  { label: "Pologne", value: "Pologne" },
  { label: "Polynésie française", value: "Polynésie française" },
  { label: "Porto Rico", value: "Porto Rico" },
  { label: "Portugal", value: "Portugal" },
  { label: "Qatar", value: "Qatar" },
  {
    label: "R.A.S. chinoise de Hong Kong",
    value: "R.A.S. chinoise de Hong Kong",
  },
  { label: "R.A.S. chinoise de Macao", value: "R.A.S. chinoise de Macao" },
  {
    label: "régions éloignées de l’Océanie",
    value: "régions éloignées de l’Océanie",
  },
  { label: "République centrafricaine", value: "République centrafricaine" },
  {
    label: "République démocratique du Congo",
    value: "République démocratique du Congo",
  },
  { label: "République dominicaine", value: "République dominicaine" },
  { label: "République tchèque", value: "République tchèque" },
  { label: "Réunion", value: "Réunion" },
  { label: "Roumanie", value: "Roumanie" },
  { label: "Royaume-Uni", value: "Royaume-Uni" },
  { label: "Russie", value: "Russie" },
  { label: "Rwanda", value: "Rwanda" },
  { label: "Sahara occidental", value: "Sahara occidental" },
  { label: "Saint-Barthélémy", value: "Saint-Barthélémy" },
  { label: "Saint-Kitts-et-Nevis", value: "Saint-Kitts-et-Nevis" },
  { label: "Saint-Marin", value: "Saint-Marin" },
  { label: "Saint-Martin", value: "Saint-Martin" },
  { label: "Saint-Pierre-et-Miquelon", value: "Saint-Pierre-et-Miquelon" },
  {
    label: "Saint-Vincent-et-les Grenadines",
    value: "Saint-Vincent-et-les Grenadines",
  },
  { label: "Sainte-Hélène", value: "Sainte-Hélène" },
  { label: "Sainte-Lucie", value: "Sainte-Lucie" },
  { label: "Samoa", value: "Samoa" },
  { label: "Samoa américaines", value: "Samoa américaines" },
  { label: "Sao Tomé-et-Principe", value: "Sao Tomé-et-Principe" },
  { label: "Sénégal", value: "Sénégal" },
  { label: "Serbie", value: "Serbie" },
  { label: "Serbie-et-Monténégro", value: "Serbie-et-Monténégro" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapour", value: "Singapour" },
  { label: "Slovaquie", value: "Slovaquie" },
  { label: "Slovénie", value: "Slovénie" },
  { label: "Somalie", value: "Somalie" },
  { label: "Soudan", value: "Soudan" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Suède", value: "Suède" },
  { label: "Suisse", value: "Suisse" },
  { label: "Suriname", value: "Suriname" },
  { label: "Svalbard et Île Jan Mayen", value: "Svalbard et Île Jan Mayen" },
  { label: "Swaziland", value: "Swaziland" },
  { label: "Syrie", value: "Syrie" },
  { label: "Tadjikistan", value: "Tadjikistan" },
  { label: "Taïwan", value: "Taïwan" },
  { label: "Tanzanie", value: "Tanzanie" },
  { label: "Tchad", value: "Tchad" },
  {
    label: "Terres australes françaises",
    value: "Terres australes françaises",
  },
  {
    label: "Territoire britannique de l'océan Indien",
    value: "Territoire britannique de l'océan Indien",
  },
  { label: "Territoire palestinien", value: "Territoire palestinien" },
  { label: "Thaïlande", value: "Thaïlande" },
  { label: "Timor oriental", value: "Timor oriental" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinité-et-Tobago", value: "Trinité-et-Tobago" },
  { label: "Tristan da Cunha", value: "Tristan da Cunha" },
  { label: "Tunisie", value: "Tunisie" },
  { label: "Turkménistan", value: "Turkménistan" },
  { label: "Turquie", value: "Turquie" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "Union européenne", value: "Union européenne" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Viêt Nam", value: "Viêt Nam" },
  { label: "Wallis-et-Futuna", value: "Wallis-et-Futuna" },
  { label: "Yémen", value: "Yémen" },
  { label: "Zambie", value: "Zambie" },
  { label: "Zimbabwe", value: "Zimbabwe" },
];

export const LIST_PAYMENT = [
  { label: "Chèque", value: "check" },
  { label: "Virement", value: "transfer" },
  { label: "Carte bancaire", value: "card" },
  { label: "Espèce", value: "cash" },
  { label: "Prélèvement", value: "prelevement" },
];

export const CONDITIONS_DE_REGLEMENT = [
  { label: "15 Jours", value: "15" },
  { label: "20 Jours", value: "20" },
  { label: "30 Jours", value: "30" },
  { label: "Saisir une date", value: "date" },
];
export const LIST_TYPR_REMISE = [
  { label: "€", value: "value" },
  { label: "%", value: "percent" },
];

export const LIST_TVA = [
  { label: "0%", value: 0 },
  { label: "5%", value: "5" },
  { label: "5,5%", value: "5.5" },
  { label: "6%", value: "6" },
  { label: "10%", value: "10" },
  {
    label: "15%",
    value: "15",
  },
  { label: "20%", value: "20" },
  { label: "25%", value: "25" },
];

export const LIST_TYPE_LIVRISION = [
  { label: "Livraison", value: "delivery" },
  { label: "Drive", value: "drive" },
];

export const LIST_STATUS = [
  { label: "Nouvelle", value: "new" },
  { label: "Réceptionnée", value: "recuperated" },
];

export const LIST_DATE = [
  { label: "Date de création", value: "created_at" },
  { label: "Date de commande", value: "achatDate" },
  { label: "Date d’échéance", value: "date_echeance" },
  { label: "Date de récupération", value: "recuperationDate" },
];

export const LIST_CIVILITY = [
  { label: "Monsieur", value: "M" },
  { label: "Madame", value: "Mme" },
  { label: "Mademoiselle", value: "Mlle" },
];

export const POSTS_TRAVEAUX = [
  { label: "Jours", value: "Jours" },
  { label: "Nuits", value: "Nuits" },
  { label: "Weekends", value: "Weekends" },
];
