import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
// import { forgotPasswordSuccess } from "../actions";
import { bindActionCreators } from "redux";
import * as actions from "actions";
// endpoints
import { forgotUrl } from "api/endpoints";

export function useForgotPass() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  async function forgotPassword(payload) {
    try {
      setFetching(true);
      await Api().post(forgotUrl(), payload);
      actionsDispatch.setSignIn(false);
      actionsDispatch.setForgotPss(false);
      // dispatch(push(`/home/list`));
    } catch (error) {
      reportError("forgotPassword error", error);
      setError(error);
    } finally {
      setFetching(false);
    }
  }

  return [{ fetching, error }, forgotPassword];
}
