import { createSelector } from "reselect";


export const currentSelect = createSelector(
  (state) => state.app,
  (app) => app
);

export const sidebarStatusSelect = createSelector(
  (state) => state.app,
  (app) => app.sidebarIsOpen
);

export const activeTabSelect = createSelector(
  (state) => state.app,
  (app) => app.activeTab
);

export const currentUserSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);
export const currentSearchSelect = createSelector(
  (state) => state.app,
  (app) => app.barSearch
);

