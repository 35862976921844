import React from "react";

const Product = ({
  image,
  name,
  description,
  priceTtc,
  priceHt,
  remise,
  priceCategory,
  handleSelect,
  can_add_to_basket,
  quantity,
  addQuantity,
  lessQuantity,
  detailsProduct,
  breakingMessage,
}) => {
  return (
    <div className="Product mb-4">
      <div onClick={() => detailsProduct()}>
        <div className="containerImage">
          <div className="image">
            <img alt="+" src={image} />
          </div>
        </div>
        <div className="breakingMessage text-center text-demi">
          {breakingMessage}
        </div>

        <div className="nameProduct text-demi">
          {name?.split("")?.length > 30 ? `${name.slice(0, 30)} ...` : name}
        </div>
        <div className="discProduct text-regular">
          {description?.split("")?.length > 30
            ? `${description.slice(0, 30)} ...`
            : description}
        </div>
      </div>
      <div className="footerProduct">
        <div className="priceProductHome">
          <div className="ttc">
            {
              // !can_add_to_basket && remise > 0 && priceCategory === "E"
              //   ? parseFloat(priceTtc - (priceTtc * remise) / 100).toFixed(2)
              //   :
              priceTtc
            }
            € TTC
          </div>
          <div className="ht">
            {
              // !can_add_to_basket && remise > 0 && priceCategory === "E"
              //   ? parseFloat(priceHt - (priceHt * remise) / 100).toFixed(2)
              //   :
              priceHt
            }
            € HT
          </div>
        </div>
        {!can_add_to_basket || quantity === 0 ? (
          <div
            onClick={() => {
              handleSelect();
            }}
            className="addBasket"
          >
            <img alt="+" src={require("assets/img/addBasket.png")} />
          </div>
        ) : (
          <div className="qteProduct mr-2">
            <div onClick={() => (quantity === 0 ? "" : lessQuantity())}>
              <img
                src={require("assets/img/iconDelete.png")}
                className=""
                width="14px"
                alt=">"
              />
            </div>
            <div>{quantity}</div>
            <div onClick={() => addQuantity()}>
              <img
                src={require("assets/img/iconAdd.png")}
                className=""
                width="14px"
                alt=">"
              />
            </div>
          </div>
        )}
      </div>
      {remise > 0 && priceCategory === "E" ? (
        <div className="promoProduct text-demi">-{remise}%</div>
      ) : remise > 0 ? (
        <div className="promoProduct text-demi">-{remise}%</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Product;
