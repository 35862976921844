import React, { useContext, useState, useLayoutEffect, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { Row, Col, Label, Button, Spinner, FormGroup } from "reactstrap";
import { detailsStateSelect } from "modules/Orders/selector";
import apiConfig from "config/apiConfig";
import * as actionsOrder from "modules/Orders/actions";
import { bindActionCreators } from "redux";
import { currentSelect } from "modules/App/selector";
import Api from "api/api";
import { useConfirmeOrders } from "modules/Orders/hooks/useConfirmOrders";
import { useDeleteOrders } from "modules/Orders/hooks/useDeleteOrders";
import { useCreatePayment } from "modules/Orders/hooks/usePayment";
import Select from "react-select";
import moment from "moment";
import DateTimeSimpel from "components/dateTimeSimpel";
import AppInput from "components/AppInput";
import Loader from "components/LoaderFull";
import {
  LIST_PAYMENT,
  CONDITIONS_DE_REGLEMENT,
  LIST_TVA,
  LIST_TYPE_LIVRISION,
} from "config/app";
// actions
import * as actions from "actions";

const useStyles = makeStyles({
  list: {
    width: 460,
    height: "100%",
  },
  fullList: {
    width: "auto",
  },
});

const CreateOrder = (props) => {
  const customStyles1 = {
    container: (provided) => ({
      ...provided,
      backgroundColor: "#fffff",
      borderRadius: "5px",
      borderWidth: "1px",
      borderColor: "#e3e3e3",
      height: "45px",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fffff",
      borderRadius: "5px",
      borderWidth: "1px",
      borderColor: "#e3e3e3",
      height: "45px",
      width: "100%",
      color: "#373737",
      fontSize: "1.05em",
      fontWeight: "bold",
    }),
  };
  const { BASE_URL } = apiConfig;
  const classes = useStyles();
  const { current } = useSelector(detailsStateSelect) || {};
  const { showOrder, showBasket, currentUser } =
    useSelector(currentSelect) || {};

  const [state, setState] = React.useState({
    right: false,
    typeLivraison: null,
    paiement: null,
    isConfirmed: false,
    address1: currentUser.address1,
  });
  const dispatch = useDispatch();
  const actionsDispatchOrder = bindActionCreators(
    { ...actionsOrder },
    dispatch
  );
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  // Get Order status Basket
  const [orderBasket, setOrderBasket] = useState([]);
  const [
    { fetchingConfirmeOrders, errorUpdate, data, fetchingConfirmeCommande },
    ConfirmeOrders,
  ] = useConfirmeOrders(orderBasket[0]?.id);

  const [{ fetching, error }, createPayment] = useCreatePayment(
    orderBasket[0]?.id
  );
  const [{ fetchingDelete, errorDelete }, deleteOrders] = useDeleteOrders(
    orderBasket[0]?.id
  );

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api().get(`/orders/?$filter={"status": "basket"}`);
        setOrderBasket(data?.value);
      } catch (err) {
        console.log(err);
        // maybe display an error message
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.status === "new" && data.paymentMode === "card") {
      createPayment(data.id);
      actionsDispatch.setOrder(false);
      actionsDispatch.setBasket(false);
      actionsDispatchOrder.emptyBasket();
    } else if (data.status === "new") {
      actionsDispatch.setOrder(false);
      actionsDispatch.setBasket(false);
      actionsDispatchOrder.emptyBasket();
      actionsDispatch.setOrderSuccuss(true);
    }
  }, [fetchingConfirmeCommande]);

  const onUpdate = () => {
    const payload = {
      products: orderBasket[0].products.map((item) => {
        return {
          product: item.id,
          quantity: parseFloat(item.quantity),
        };
      }),
      // comment: state.commentaire,
      // paymentMode: state.paiement?.value,
      // deliveryMode: state.typeLivraison?.value,
      // deliveryDate: state.date_drive,
      // deliveryHour: state.hours_drive?.label,
      // status: "new",

      paymentMode: state.paiement?.value,
      deliveryMode: state.typeLivraison?.value,
      deliveryDay: state.date_drive,
      // deliveryDate: deliveryDay.date || new Date(),
      // ...(deliveryMode === 'delivery' && {deliveryRound: deliveryDay.round}),
      ...(state.typeLivraison?.value === "drive" && { drive: idDrive }),
      deliveryHour: state.hours_drive?.label,
      ...(state.typeLivraison?.value === "delivery" && {
        zipcode: state.zipcode || currentUser.zipcode,
      }),
      ...(state.typeLivraison?.value === "delivery"
        ? { address1: state.address1 || currentUser.address1 }
        : { address1: "5 Rue des Crêts, 01000 Bourg-en-Bresse, France" }), //if drive then send mf address
      ...(state.typeLivraison?.value === "delivery"
        ? { city: state.city || currentUser.city }
        : { city: "Bourg-en-Bressse" }),
      ...(state.typeLivraison?.value === "delivery" && {
        location: {
          coordinates: state.coordinates || currentUser.location.coordinates,
        },
      }),
      // ...(restaurant && {restaurant}),
      status: "new",
      comment: state.commentaire,
      // totalTtc,
    };
    ConfirmeOrders(payload);
  };

  const FormValidations =
    state.typeLivraison?.value === "delivery"
      ? !state.address1 || !state.paiement
      : !state.hours_drive || !state.date_drive || !state.paiement;

  const [open, setOpen] = React.useState(props.isOpen);

  //List Heure du drive par date
  const [listHeures, setListHeures] = useState([]);
  const [erorrHeures, setErorrHeures] = useState("");
  const [idDrive, setIdDrive] = useState("");
  useEffect(() => {
    new Promise(async (resolve) => {
      try {
        const data = await Api().get(
          `/drives/${moment(state?.date_drive).format("DDMMYYYY")}`
        );
        const formatted = data?.timeSlots.map((one) => ({
          value: one.timeSlot,
          label: one.timeSlot,
        }));
        setListHeures(formatted);
        resolve(formatted);
        setErorrHeures("");
        setIdDrive(data._id);
      } catch (error) {
        setErorrHeures(error);
        resolve([]);
        setListHeures([]);
      }
    });
  }, [state.date_drive]);

  return (
    <React.Fragment key={"right"}>
      {/* <Button onClick={(e) => props.onShowBasket()}>{"right"}</Button> */}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={(e) => props.onCloseModal()}
      >
        <div className={`${clsx(classes.list)} createOrder mb-2`}>
          <div className="header">
            <div
              onClick={() => {
                if (state.isConfirmed) {
                  setState({ ...state, isConfirmed: false });
                } else {
                  actionsDispatch.setOrder(!showOrder);
                  actionsDispatch.setBasket(!showBasket);
                }
              }}
            >
              <img
                src={require("assets/img/iconBack.png")}
                className="ml-2"
                width="24px"
                alt=">"
              />
            </div>
            <div>
              {state.isConfirmed ? (
                <div>
                  <span className="title text-bold">Confirmation</span>
                </div>
              ) : (
                <div
                  onClick={() => {
                    deleteOrders();
                    actionsDispatchOrder.emptyBasket();
                  }}
                >
                  <span className="title text-bold">Mon panier</span>
                  <span className="sous-title ml-4">
                    vider le panier
                    <img
                      src={require("assets/img/iconViderBasket.png")}
                      className="ml-2"
                      width="14px"
                      alt=">"
                    />
                  </span>
                </div>
              )}

              <div className="sous-title-articles">
                {current.length} articles
              </div>
            </div>
            <div onClick={(e) => props.onCloseModal()} className="imageClose">
              <img
                src={require("assets/img/iconCloseBasket.png")}
                className=""
                width="16px"
                alt=">"
              />
            </div>
          </div>
          {state.isConfirmed ? (
            <div className="ContentBody">
              <AppInput
                label="Êtes-vous sûr de vouloir envoyer cette Commande ?"
                type="textarea"
                placeholder="Vous pouvez laisser une note complémentaire pour 
                  votre Commande"
                disabled={false}
                value={state.commentaire}
                onChange={(t) => setState({ ...state, commentaire: t })}
              />
              <div className="footerConfirm mt-4">
                <div
                  onClick={() => setState({ ...state, isConfirmed: false })}
                  className="text-bold"
                >
                  Retourner
                </div>
                <div className="">
                  <Button
                    disabled={fetchingConfirmeCommande}
                    className="BtnSignIn text-demi"
                    color="default"
                    type="button"
                    onClick={(e) => onUpdate(e)}
                  >
                    <div className="text-bold">Je confirme</div>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="ContentBody">
                <Row>
                  <Col xs="12" sm="6" md="8">
                    <FormGroup>
                      <Label className="text-black">Moyen de paiement</Label>
                      <Select
                        styles={customStyles1}
                        isSearchable={true}
                        options={LIST_PAYMENT}
                        placeholder={"Sélectionner"}
                        onChange={(v) => setState({ ...state, paiement: v })}
                        value={state.paiement}
                      />
                      <>
                        {state.paiement !== null && (
                          <div
                            onClick={() =>
                              setState({ ...state, paiement: null })
                            }
                            className="iconClean"
                          ></div>
                        )}
                        <div className="input-addon">
                          <img
                            width="14px"
                            alt="+"
                            src={require("assets/img/iconSelect.png")}
                          />
                        </div>
                      </>
                    </FormGroup>
                  </Col>
                </Row>
                {state.paiement?.value === "transfer" && (
                  <Row>
                    <Col className="mb-4">
                      <div style={{ color: "#3789EF" }}>
                        IBAN : FR90 3000 2027 3200 0023 0505 S63
                      </div>
                      <div style={{ color: "#3789EF" }}>BIC : CRLYFRPP</div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs="12" sm="6" md="8">
                    <FormGroup>
                      <Label className="text-black">Mode de récupération</Label>
                      <Select
                        styles={customStyles1}
                        isSearchable={true}
                        options={LIST_TYPE_LIVRISION}
                        placeholder={"Sélectionner"}
                        onChange={(v) => {
                          setState({ ...state, typeLivraison: v });
                        }}
                        value={state.typeLivraison}
                      />
                      <>
                        {state.typeLivraison !== null &&
                          (true ? (
                            ""
                          ) : (
                            <div
                              onClick={() =>
                                setState({ ...state, typeLivraison: null })
                              }
                              className="iconClean"
                            ></div>
                          ))}
                        <div className="input-addon">
                          <img
                            width="14px"
                            alt="+"
                            src={require("assets/img/iconSelect.png")}
                          />
                        </div>
                      </>
                    </FormGroup>
                  </Col>
                </Row>
                {state.typeLivraison?.value === "delivery" && (
                  <Row>
                    <Col xs="12" sm="6" md="6">
                      <AppInput
                        label="Adresse"
                        className="inputSearch"
                        id="login-pwd"
                        name="pwd"
                        placeholder=""
                        type={"text"}
                        value={state.address1}
                        onChange={(t) => setState({ ...state, address1: t })}
                      />
                    </Col>
                  </Row>
                )}
                {state.typeLivraison?.value === "drive" && (
                  <>
                    <Row>
                      <Col className="mb-4">
                        <div>Adresse de récupération</div>
                        <div className="text-bold">
                          Yamiz : 5 Rue des Crêts, 01000 Bourg-en-Bresse, France
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="6" md="6">
                        <DateTimeSimpel
                          label="Jour du Drive"
                          value={state.date_drive}
                          isClearable={true}
                          disabled={false}
                          placeholderText={"Sélectionner"}
                          minDate={new Date()}
                          onChange={(e) => {
                            setState({
                              ...state,
                              date_drive: e,
                            });
                          }}
                          leftaddon={
                            <div
                              style={{ width: "20px", right: "14px" }}
                              className="input-addon"
                            >
                              <img
                                alt="+"
                                src={require("assets/img/iconDate.png")}
                              />
                            </div>
                          }
                        />
                      </Col>
                      <Col xs="12" sm="6" md="6">
                        <FormGroup>
                          <Label className="text-black">Heure du Drive</Label>
                          <Select
                            label="Heure du drive"
                            disabled={false}
                            required
                            styles={customStyles1}
                            placeholder={"Sélectionner"}
                            name="hours_drive"
                            id="hours_drive"
                            isClearable
                            isSearchable
                            value={state.hours_drive}
                            options={listHeures}
                            onChange={(c) => {
                              setState({ ...state, hours_drive: c });
                            }}
                            className="filter-input mr-4"
                            tabIndex={2}
                          />
                          <div className="input-addon">
                            <img
                              width="14px"
                              alt="+"
                              src={require("assets/img/iconSelect.png")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}

                {erorrHeures && (
                  <h6 className="error">
                    {erorrHeures.message || "Erreur de connexion"}
                  </h6>
                )}
              </div>
              <div className="footer">
                <div className="separateur"></div>
                <div className="summary">
                  <div className="total">Total HT</div>
                  <div className="price text-demi">
                    {orderBasket.length > 0 && current.length > 0
                      ? orderBasket[0]?.totalWithDiscount
                      : 0}{" "}
                    €
                  </div>
                </div>
                <Divider />
                <div className="summary">
                  <div className="total">Remise (3%)</div>
                  <div className="price text-demi">
                    -
                    {orderBasket.length > 0 && current.length > 0
                      ? parseFloat(
                          orderBasket[0]?.totalTtcWithDiscount * 0.03
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </div>
                </div>
                <Divider />
                <div className="summary">
                  <div className="total">Total HT (aprés remise)</div>
                  <div className="price text-demi">
                    {orderBasket.length > 0 && current.length > 0
                      ? (
                          parseFloat(orderBasket[0]?.totalWithDiscount) -
                          parseFloat(
                            orderBasket[0]?.totalTtcWithDiscount * 0.03
                          )
                        ).toFixed(2)
                      : 0}{" "}
                    €
                  </div>
                </div>
                <Divider />
                <div className="summary">
                  <div className="total">Tva</div>
                  <div className="price text-demi">
                    {orderBasket.length > 0 && current.length > 0
                      ? orderBasket[0]?.totalTvaWithDiscount
                      : 0}{" "}
                    €
                  </div>
                </div>
                <Divider />
                <div className="summary">
                  <div style={{ color: "#23B573" }} className="total text-demi">
                    Total TTC
                  </div>
                  <div style={{ color: "#23B573" }} className="price text-demi">
                    {orderBasket.length > 0 && current.length > 0
                      ? (
                          parseFloat(orderBasket[0]?.totalTtcWithDiscount) -
                          parseFloat(
                            orderBasket[0]?.totalTtcWithDiscount * 0.03
                          )
                        ).toFixed(2)
                      : 0}
                    €
                  </div>
                </div>
                <Divider />
                <div className="BtnContinuer">
                  <Button
                    disabled={
                      fetchingConfirmeOrders
                        ? fetchingConfirmeOrders
                        : FormValidations
                    }
                    className="BtnSignIn text-demi"
                    color="default"
                    type="button"
                    onClick={(e) => setState({ ...state, isConfirmed: true })}
                  >
                    <div className="text-bold">
                      {" "}
                      Commander :{" "}
                      {orderBasket.length > 0 && current.length > 0
                        ? (
                            parseFloat(
                              orderBasket[0]?.totalTtcWithDiscount
                            ).toFixed(2) -
                            parseFloat(
                              orderBasket[0]?.totalTtcWithDiscount * 0.03
                            ).toFixed(2)
                          ).toFixed(2)
                        : 0}
                      €
                    </div>
                    <div>
                      <img
                        src={require("assets/img/iconSend.png")}
                        className="ml-2"
                        width="24px"
                        alt=">"
                      />
                    </div>
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Drawer>
      {fetchingConfirmeCommande && <Loader />}
    </React.Fragment>
  );
};

export default CreateOrder;
