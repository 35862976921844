import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { createOrderUrl } from "../api/endpoints";

export function useCreateOrders() {
  const [fetchingOrders, setFetchingOrders] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [dataCreateOrders, setDataCreateOrders] = useState(null);
  async function createOrders(payload, uploadPayload) {
    try {
      setFetchingOrders(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      const data = await Api().post(createOrderUrl(payload), {
        ...payload,
        ...(logoData && { image: logoData.file }),
      });
      setDataCreateOrders(data);
      // dispatch(push(`/home/list`));
    } catch (error) {
      reportError("createProduct error", error);
      setError(error);
      setFetchingOrders(false);
    } finally {
      // setFetchingOrders(false);
    }
  }

  return [{ fetchingOrders, error, dataCreateOrders }, createOrders];
}
