import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { orderByIdUrl } from "../api/endpoints";

export function useUpdateOrders(id) {
  const [fetchingUpdateOrders, setFetchingUpdateOrders] = useState(false);
  const [fetchingUpdateCommande, setFetchingUpdateCommande] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  async function updateOrders(payload, uploadPayload) {
    try {
      setFetchingUpdateOrders(true);
      setFetchingUpdateCommande(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      const data = await Api().put(orderByIdUrl(id), {
        ...payload,
        ...(logoData && { image: logoData.file }),
      });
      setData(data);
      // setFetchingUpdateOrders(false);
      setFetchingUpdateCommande(false);
      // dispatch(push(`/home/list`));
    } catch (errorUpdate) {
      reportError("Orders errorUpdate", errorUpdate);
      setErrorUpdate(errorUpdate);
      setFetchingUpdateOrders(false);
    } finally {
      //   setFetchingUpdateOrders(false);
    }
  }

  return [
    { fetchingUpdateOrders, errorUpdate, data, fetchingUpdateCommande },
    updateOrders,
  ];
}
