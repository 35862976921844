export function meUrl() {
  return "/me";
}

export function logoutUrl() {
  return "/auth/signout";
}

export function uploadUrl() {
  return "/files";
}
export function uploadReferencesUrl() {
  return "/admin/references/bulk";
}
export function uploadFournisseurUrl() {
  return "/admin/fournisseurs/bulk";
}

export function categoriesUrl() {
  return "/user/categories";
}
export function catsUrl() {
  return `/categories?&$top=100&$select=name,image`;
}
export function featuresUrl() {
  return `/features?&$top=100&$select=name,image`;
}

export function loginUrl() {
  return "/auth/signin";
}

export function signupUrl() {
  return "/auth/signup";
}

export function forgotUrl() {
  return "/auth/forgot";
}
export function verifiEmailUrl() {
  return "/auth/users/verify-email";
}