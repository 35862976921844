import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { createPaymentUrl } from "../api/endpoints";

export function useCreatePayment() {
  const [fetchingPayment, setFetchingPayment] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [dataCreatePayment, setDataCreatePayment] = useState(null);
  async function createPayment(id) {
    try {
      setFetchingPayment(true);
      const data = await Api().post(createPaymentUrl(id));
      window.location.replace(data.paymentUrl);
      // dispatch(push(`/home/list`));
    } catch (error) {
      reportError("createProduct error", error);
      setError(error);
      setFetchingPayment(false);
    } finally {
      // setFetchingPayment(false);
    }
  }

  return [{ fetchingPayment, error, dataCreatePayment }, createPayment];
}
