import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Modal, ModalBody, Button } from "reactstrap";

import { useSelector } from "react-redux";
import { Api } from "api";
import { logoutSuccess } from "actions";
import { currentUserSelect } from "../../modules/App/selector";
import AppInput from "components/AppInput";
import { useSignIn } from "./hooks/useSignIn";
const Signin = (props) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const { inputSearch } = useSelector(currentUserSelect) || {};
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openPassword, setOpenPassword] = useState(false);

  const [{ fetching, error }, signin] = useSignIn();

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = { username, password };
    signin(payload);
  };

  return (
    <Modal
      size="lg"
      isOpen={props.isOpen}
      toggle={() => {
        if (!fetching) {
          props.onCloseModal();
        }
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title text-bold">Authentification</h5>
        <button
          onClick={(e) => {
            if (!fetching) {
              props.onCloseModal();
            }
          }}
          aria-label="Close"
          className="close"
          type="button"
        >
          <img
            src={require("assets/img/iconClose.png")}
            className=""
            width="16px"
            alt=">"
          />
        </button>
      </div>
      <ModalBody className="signin">
        <div>
          <AppInput
            label="Email"
            className="inputSearch"
            required
            id="login-pwd"
            name="pwd"
            placeholder=""
            type={"text"}
            value={username}
            onChange={(t) => setUsername(t)}
          />
        </div>
        <div className="inputPassword">
          <AppInput
            label="Mot de passe"
            required
            className="inputSearch"
            id="login-pwd"
            name="pwd"
            placeholder=""
            type={openPassword ? "text" : "password"}
            value={password}
            onChange={(t) => setPassword(t)}
            leftaddon={
              <div
                onClick={() => setOpenPassword(!openPassword)}
                className="input-addon"
              >
                <img
                  alt="+"
                  src={
                    openPassword
                      ? require("assets/img/eye-b.png")
                      : require("assets/img/eye.png")
                  }
                />
              </div>
            }
          />
        </div>
        {error && (
          <h6 className="error">{error.message || "Erreur de connexion"}</h6>
        )}
      </ModalBody>

      <div className="modal-footer logout">
        <Button
          disabled={fetching}
          className="BtnSignIn text-bold"
          color="default"
          type="button"
          onClick={(e) => onSubmit(e)}
        >
          Se Connecter
        </Button>
      </div>
      <div
        onClick={() => {
          props.onShowSignUp();
          props.onCloseModal();
        }}
        className="TextSignUp text-demi mt-4"
      >
        Je n’ai pas de compte
      </div>
      <div
        onClick={() => {
          props.onShowFargot();
          props.onCloseModal();
        }}
        className="Textforgot text-regular mt-2 mb-4"
      >
        J’ai oublié mon mot de passe
      </div>
    </Modal>
  );
};

export default Signin;
