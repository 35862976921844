import React from "react";

const Header = () => {
  return (
    <div className="header">
      <div className="titre text-demi">
        Toutes vos courses livrées à domicile !
      </div>
    </div>
  );
};

export default Header;
