import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Modal, ModalBody, Button } from "reactstrap";

import { useSelector } from "react-redux";
import { currentUserSelect } from "../../modules/App/selector";
import AppInput from "components/AppInput";

const SignupSuccess = (props) => {
  const dispatch = useDispatch();
  const { inputSearch } = useSelector(currentUserSelect) || {};
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(true);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={() => {
        props.onCloseModal();
      }}
    >
      <div className="modal-header">
        <button
          onClick={(e) => {
            props.onCloseModal();
          }}
          aria-label="Close"
          className="close"
          type="button"
        >
          <img
            src={require("assets/img/iconClose.png")}
            className=""
            width="16px"
            alt=">"
          />
        </button>
      </div>
      <ModalBody className="forgot">
        <div className="text-center">
          <img
            src={require("assets/img/signupSuccess.png")}
            className=""
            width="216px"
            alt=">"
          />
        </div>

        <div className="text-demi ">
          Vérifiez votre boite {props.email} et cliquez sur le lien de
          confirmation
        </div>
        <div className="">NB: Vérifiez le courrier indésirable au cas où</div>
      </ModalBody>

      <div
        style={{ display: "flex", justifyContent: "center" }}
        className=" my-4 modal-footer logout"
      >
        <Button
          onClick={(e) => {
            props.onCloseModal();
          }}
          className="BtnSignIn text-bold"
          color="default"
          type="button"
        >
          Retourner à l’accueil
        </Button>
      </div>
    </Modal>
  );
};

export default SignupSuccess;
