// import front-end config
import { ADD_BASKET, DELETE_BASKET ,EMPTY_BASKET} from "../actionsTypes";
import { LOGOUT_S } from "actionsTypes";

// import {REHYDRATE} from 'redux-persist/lib/constants';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  current: [],
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function details(state = initialState, action) {
  switch (action.type) {
    case ADD_BASKET: {
      let newList = [...state.current];
      if (state.current?.length === 0) {
        newList.push(action.payload);
      } else if (
        !(
          state.current.filter((data) => data._id === action.payload?._id)
            .length > 0
        )
      ) {
        newList.push({ ...action.payload });
      } else {
        newList = [];
        state.current.map((item) => {
          if (item._id === action.payload?._id) {
            newList.push(action.payload);
          } else {
            newList.push(item);
          }
        });
      }

      return {
        ...state,
        current: newList,
      };
    }
    // return initialState;

    case DELETE_BASKET:
      return {
        ...state,
        current: state?.current?.filter((item) => item.id !== action.payload),
      };
      case EMPTY_BASKET:
        return initialState;

    case LOGOUT_S:
      return initialState;

    default:
      return state;
  }
}
