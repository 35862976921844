import React, { useEffect, useState } from "react";
import Header from "components/Header/header";
import BarSearch from "components/BarSearch/barSearch";
import Menu from "components/Menu/menu";
import SignIn from "components/SignIn/signIn";
import SignUp from "components/SignUp/signUp";
import ForgotPass from "components/ForgotPss/forgotPass";
import Basket from "components/Basket/basket";
import CreateOrder from "modules/Orders/containers/create";
import OrderSuccess from "components/paymentSucssu/orderSuccess";
import SignupSuccess from "components/SignUp/signupSuccess";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import * as actions from "actions";
import { currentSelect } from "../selector";
import { push } from "connected-react-router";

const AppLayout = (props) => {
  const {
    showSingIn,
    showForgot,
    showSingUp,
    showBasket,
    showOrder,
    showSingUpSuccess,
    showOrderSuccess,
    email,
  } = useSelector(currentSelect) || {};
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);

  useEffect(() => {
    if (window.location?.hash?.split("=")[1] === "ok") {
      actionsDispatch.setOrderSuccuss(true);
      actionsDispatch.setOrder(false);
    } else if (window.location?.hash?.split("=")[1] === "nok") {
      actionsDispatch.setOrder(true);
      actionsDispatch.setOrderSuccuss(false);
    }
  }, []);

  return (
    <>
      <Header />
      <BarSearch />
      <Menu />
      {showBasket && (
        <Basket
          isOpen={showBasket}
          onShowBasket={() => {
            actionsDispatch.setOrder(false);
            actionsDispatch.setBasket(false);
          }}
        />
      )}
      {showSingUpSuccess && (
        <SignupSuccess
          email={email}
          isOpen={showSingUpSuccess}
          onCloseModal={() => actionsDispatch.setSignUpSu(false)}
        />
      )}
      {showOrderSuccess && (
        <OrderSuccess
          isOpen={showOrderSuccess}
          onCloseModal={() => actionsDispatch.setOrderSuccuss(false)}
        />
      )}
      <div className="app-layout">{props.children}</div>
      {showSingIn && (
        <SignIn
          isOpen={showSingIn}
          onShowSignUp={() => actionsDispatch.setSignUp(true)}
          onCloseModal={() => actionsDispatch.setSignIn(false)}
          onShowFargot={() => actionsDispatch.setForgotPss(true)}
        />
      )}
      {showSingUp && (
        <SignUp
          isOpen={showSingUp}
          onCloseModal={() => actionsDispatch.setSignUp(false)}
          onShowSignIn={() => actionsDispatch.setSignIn(true)}
        />
      )}
      {showForgot && (
        <ForgotPass
          isOpen={showForgot}
          onCloseModal={() => actionsDispatch.setForgotPss(false)}
          onShowSignIn={() => actionsDispatch.setSignIn(true)}
        />
      )}
      {showOrder && (
        <CreateOrder
          isOpen={showOrder}
          onCloseModal={() => {
            actionsDispatch.setOrder(false);
            actionsDispatch.setBasket(false);
          }}
        />
      )}
    </>
  );
};

export default AppLayout;
