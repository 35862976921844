import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

/*
 * Import reducers
 */
import app from "./appReducer";
// import auth from "modules/Auth/reducers";
// import demandes from "modules/Demandes/reducers";
import home from "modules/Home/reducers";
import orders from "modules/Orders/reducers";

const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    // auth,
    home,
    orders,
  });

export default createReducers;
