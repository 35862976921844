import React, { useState, useEffect } from "react";
import { catsUrl, featuresUrl } from "api/endpoints";
import Api from "api/api";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { currentState } from "modules/Home/selector";

// actions
import * as actions from "actions";
const Menu = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);

  const [fetching, setFetching] = useState(false);
  const [listRayons, setListRayons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(catsUrl());
        setFetching(false);
        setListRayons(data);
      } catch {
        setFetching(false);
        // maybe display an error message
      }
    };

    fetchData();
  }, []);
  const [listFeatures, setListFeatures] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(featuresUrl());
        setFetching(false);
        setListFeatures(data);
      } catch {
        setFetching(false);
        // maybe display an error message
      }
    };

    fetchData();
  }, []);

  const { Feature } = useSelector(currentState) || {};
  return (
    <div className="MenuContante">
      <hr />
      <div className="Menu">
        <div>
          <div onClick={() => setShowMenu(!showMenu)} className="headerMenu">
            <img
              width="30px"
              height="30px"
              alt="+"
              src={require("assets/img/iconRayons.png")}
            />
            <h3 className="ml-2 text-demi">Rayons</h3>
          </div>

          {showMenu && (
            <div className="card">
              <div className="body">
                <ul>
                  {listRayons?.value?.length > 0 &&
                    listRayons?.value.map((item) => (
                      <li
                        onClick={() => {
                          actionsDispatch.setRayons({
                            name: item.name,
                            value: item.id,
                          });
                          actionsDispatch.setFeature({});
                          setShowMenu(false);
                          dispatch(push(`/home/list`));
                        }}
                      >
                        <div>{item.name} </div>
                        <img
                          width="20px"
                          height="20px"
                          alt="+"
                          src={require("assets/img/iconMenuRayons.png")}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
          <div
            onClick={() => setShowMenu(!showMenu)}
            className={showMenu ? "BackgroundMenu" : ""}
          ></div>
        </div>
        <div className="containerFeature">
          {listFeatures?.value?.length > 0 &&
            listFeatures?.value.map((item) => (
              <div
                onClick={() => {
                  actionsDispatch.setFeature({
                    name: item.name,
                    value: item.id,
                  });
                  actionsDispatch.setRayons({});
                  dispatch(push(`/home/list`));
                }}
                className="Feature text-demi"
                style={{
                  color: Feature?.name === item.name ? "#23B573" : "#373737",
                }}
              >
                {item.name}
              </div>
            ))}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Menu;
