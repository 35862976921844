import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
import { OrderConfirmationUrl } from "../api/endpoints";

export function useConfirmeOrders(id) {
  const [fetchingConfirmeOrders, setFetchingConfirmeOrders] = useState(false);
  const [fetchingConfirmeCommande, setFetchingConfirmeCommande] = useState(false);
  const [errorConfirme, setErrorConfirme] = useState(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  async function ConfirmeOrders(payload, uploadPayload) {
    try {
      setFetchingConfirmeOrders(true);
      setFetchingConfirmeCommande(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      const data = await Api().put(OrderConfirmationUrl(id), {
        ...payload,
        ...(logoData && { image: logoData.file }),
      });
      setData(data);
      // setFetchingConfirmeOrders(false);
      setFetchingConfirmeCommande(false);
      // dispatch(push(`/home/list`));
    } catch (errorConfirme) {
      reportError("Orders errorConfirme", errorConfirme);
      setErrorConfirme(errorConfirme);
      setFetchingConfirmeOrders(false);
    } finally {
      //   setFetchingConfirmeOrders(false);
    }
  }

  return [
    { fetchingConfirmeOrders, errorConfirme, data, fetchingConfirmeCommande },
    ConfirmeOrders,
  ];
}
