import React, { useContext, useState, useLayoutEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { Row, Col, Button } from "reactstrap";
import { detailsStateSelect } from "modules/Orders/selector";
import apiConfig from "config/apiConfig";
import * as actionsOrder from "modules/Orders/actions";
import { bindActionCreators } from "redux";
import { currentSelect } from "../../modules/App/selector";
import Api from "api/api";
import { useUpdateOrders } from "modules/Orders/hooks/useUpdateOrders";
import { useDeleteOrders } from "modules/Orders/hooks/useDeleteOrders";
import { push } from "connected-react-router";
// actions
import * as actions from "actions";

const useStyles = makeStyles({
  list: {
    width: 460,
    height: "100%",
  },
  fullList: {
    width: "auto",
  },
});

const OrderSuccess = (props) => {
  const { BASE_URL } = apiConfig;
  const classes = useStyles();
  const [open, setOpen] = useState(
    window.location?.hash?.split("=")[1] === "ok" ? true : false
  );

  const dispatch = useDispatch();

  return (
    <React.Fragment key={"right"}>
      {/* <Button onClick={(e) => props.onShowBasket()}>{"right"}</Button> */}
      <Drawer
        anchor={"right"}
        open={
          window.location?.hash?.split("=")[1] === "ok" ? open : props.isOpen
        }
        onClose={(e) => {
          setOpen(!open);
          dispatch(push(`/home/list`));
          props.onCloseModal(false);
        }}
      >
        <div className={`${clsx(classes.list)} basket mb-2`}>
          <div className="header">
            <div>
              <div>
                <span className="title text-bold">Commande reçue</span>
              </div>
            </div>
            <div
              onClick={(e) => {
                setOpen(!open);
                dispatch(push(`/home/list`));
                document.location.reload(true);
                props.onCloseModal(false);
              }}
              className="imageClose"
            >
              <img
                src={require("assets/img/iconCloseBasket.png")}
                className=""
                width="16px"
                alt=">"
              />
            </div>
          </div>
          <div className="ContentBody">
            <div className="text-demi text-center">
              Votre commande a bien été prise en charge
            </div>
            <div className="text-center  my-4">
              <img
                src={require("assets/img/orderSuccess.png")}
                className=""
                width="260px"
                alt=">"
              />
            </div>
            <div
              onClick={() => {
                setOpen(!open);
                dispatch(push(`/home/list`));
                document.location.reload(true);
                props.onCloseModal(false);
              }}
              style={{ color: "#116EFF", cursor: "pointer" }}
              className="text-demi text-center"
            >
              Retourner à l’accueil
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default OrderSuccess;
