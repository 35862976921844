import React from "react";

const Slider = () => {
  return (
    <div className="slider mb-4">
      <div className="image">
        <img width="100%" alt="+" src={require("assets/img/imageSlider.png")} />
      </div>
    </div>
  );
};

export default Slider;
