export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_GUEST = "SET_USER_GUEST";

export const LOGOUT_S = "LOGOUT_S";

export const GET_CURRENT_USER_R = "GET_CURRENT_USER_R";
export const GET_CURRENT_USER_F = "GET_CURRENT_USER_F";

export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_CURRENT_SEARCH = "SET_CURRENT_SEARCH";
export const SET_SEARCH = "SET_SEARCH";
export const SET_RAYONS = "SET_RAYONS";
export const SET_SIGNIN = "SET_SIGNIN";
export const SET_FORGOTPASS = "SET_FORGOTPASS";
export const SET_SIGNUP = "SET_SIGNUP";
export const SET_BASKET = "SET_BASKET";
export const SET_ORDER = "SET_ORDER";
export const SET_SIGNUP_SU = "SET_SIGNUP_SU";
export const SET_EMAIL = "SET_EMAIL";
export const SET_ORDER_SU = "SET_ORDER_SU";
export const SET_FEATURE = "SET_FEATURE";
