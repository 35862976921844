import React, { useContext, useState, useLayoutEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { Row, Col, Button } from "reactstrap";
import { detailsStateSelect } from "modules/Orders/selector";
import apiConfig from "config/apiConfig";
import * as actionsOrder from "modules/Orders/actions";
import { bindActionCreators } from "redux";
import { currentSelect } from "../../modules/App/selector";
import Api from "api/api";
import { useUpdateOrders } from "modules/Orders/hooks/useUpdateOrders";
import { useDeleteOrders } from "modules/Orders/hooks/useDeleteOrders";

// actions
import * as actions from "actions";

const useStyles = makeStyles({
  list: {
    width: 460,
    height: "100%",
  },
  fullList: {
    width: "auto",
  },
});

const Basket = (props) => {
  const { BASE_URL } = apiConfig;
  const classes = useStyles();
  const { current } = useSelector(detailsStateSelect) || {};
  const { showOrder, showBasket } = useSelector(currentSelect) || {};

  const [state, setState] = React.useState({
    right: false,
  });
  const dispatch = useDispatch();
  const actionsDispatchOrder = bindActionCreators(
    { ...actionsOrder },
    dispatch
  );
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  // Get Order status Basket
  const [orderBasket, setOrderBasket] = useState([]);
  const [
    { fetchingUpdateOrders, errorUpdate, data },
    updateOrders,
  ] = useUpdateOrders(orderBasket[0]?.id);
  const [{ fetchingDelete, errorDelete }, deleteOrders] = useDeleteOrders(
    orderBasket[0]?.id
  );
  const [fetching, setFetching] = useState(true);
  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api().get(`/orders/?$filter={"status": "basket"}`);
        // actionsDispatchOrder.emptyBasket();
        setOrderBasket(data?.value);
      } catch (err) {
        console.log(err);
        // maybe display an error message
      }
    };
    fetchData();
  }, []);

  useLayoutEffect(() => {
    setOrderBasket([data]);
  }, [data]);

  // update  Order
  useLayoutEffect(() => {
    if (orderBasket[0]?.id && orderBasket[0]?.status === "basket") {
      const payload = {
        products: current.map((item) => ({
          product: item.id,
          quantity: item.quantity,
        })),
        status: "basket",
      };
      updateOrders(payload);
    }
  }, [fetching]);

  useLayoutEffect(() => {
    if (orderBasket.length > 0) {
      Array.isArray(orderBasket[0]?.products) &&
        orderBasket[0].products.map((item) =>
          actionsDispatchOrder.addBasket({
            ...item,
            can_add_to_basket: true,
            quantity: item.quantity,
          })
        );
    }
  }, [orderBasket]);

  const [open, setOpen] = React.useState(props.isOpen);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  // More Quantity
  const addQuantity = (item) => {
    actionsDispatchOrder.addBasket({
      ...item,
      can_add_to_basket: true,
      quantity: item.quantity + 1,
    });
    setFetching(!fetching);
  };

  // Less Quantity

  const lessQuantity = (item) => {
    if (item.quantity - 1 === 0) {
      actionsDispatchOrder.deleteBasket(item.id);
    } else {
      actionsDispatchOrder.addBasket({
        ...item,
        can_add_to_basket: true,
        quantity: item.quantity - 1,
      });
    }
    setFetching(!fetching);
  };

  return (
    <React.Fragment key={"right"}>
      {/* <Button onClick={(e) => props.onShowBasket()}>{"right"}</Button> */}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={(e) => props.onShowBasket()}
      >
        <div className={`${clsx(classes.list)} basket mb-2`}>
          <div className="header">
            <div>
              <div
                onClick={() => {
                  actionsDispatchOrder.emptyBasket();
                  deleteOrders();
                }}
              >
                <span className="title text-bold">Mon panier</span>
                <span className="sous-title ml-4">
                  vider le panier
                  <img
                    src={require("assets/img/iconViderBasket.png")}
                    className="ml-2"
                    width="14px"
                    alt=">"
                  />
                </span>
              </div>
              <div className="sous-title-articles">
                {current.length} articles
              </div>
            </div>
            <div onClick={(e) => props.onShowBasket()} className="imageClose">
              <img
                src={require("assets/img/iconCloseBasket.png")}
                className=""
                width="16px"
                alt=">"
              />
            </div>
          </div>
          <div className="ContentBody">
            {current.map((item) => (
              <>
                <Row className=" body">
                  <Col className="imageProduct" xs="12" md="3">
                    <img
                      src={`${BASE_URL}${item?.imageUrl}`}
                      className=""
                      width="80px"
                      height="60px"
                      alt=">"
                    />
                  </Col>
                  <Col className="nameProduct text-bold" xs="12" md="6">
                    {item.name}
                  </Col>
                  <Col xs="12" md="3">
                    <div className="qteProduct mr-2">
                      <div onClick={(e) => lessQuantity(item)}>
                        <img
                          src={require("assets/img/iconDelete.png")}
                          className=""
                          width="14px"
                          alt=">"
                        />
                      </div>
                      <div>{item.quantity}</div>
                      <div onClick={() => addQuantity(item)}>
                        <img
                          src={require("assets/img/iconAdd.png")}
                          className=""
                          width="14px"
                          alt=">"
                        />
                      </div>
                    </div>
                    <div className="priceProduct text-demi mr-4">
                      {item.price} €
                    </div>
                  </Col>
                </Row>
                <Divider />
              </>
            ))}
          </div>
          <div className="footer">
            <div className="separateur"></div>
            <div className="summary">
              <div className="total">Total HT</div>
              <div className="price text-demi">
                {orderBasket.length > 0 && current.length > 0
                  ? orderBasket[0]?.totalWithDiscount
                  : 0}{" "}
                €
              </div>
            </div>
            <Divider />
            <div className="summary">
              <div className="total">Tva</div>
              <div className="price text-demi">
                {orderBasket.length > 0 && current.length > 0
                  ? orderBasket[0]?.totalTvaWithDiscount
                  : 0}{" "}
                €
              </div>
            </div>
            <Divider />
            <div className="summary">
              <div className="total">Total TTC</div>
              <div className="price text-demi">
                {orderBasket.length > 0 && current.length > 0
                  ? orderBasket[0]?.totalTtcWithDiscount
                  : 0}{" "}
                €
              </div>
            </div>
            <Divider />
            <div className="BtnContinuer">
              <Button
                disabled={!current.length > 0}
                className="BtnSignIn text-demi"
                color="default"
                type="button"
                onClick={(e) => {
                  actionsDispatch.setOrder(!showOrder);
                  actionsDispatch.setBasket(!showBasket);
                }}
              >
                Continuer :{" "}
                {orderBasket.length > 0 && current.length > 0
                  ? orderBasket[0]?.totalTtcWithDiscount
                  : 0}{" "}
                €
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default Basket;
