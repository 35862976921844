export function catProductsUrl(skip, q, Rayons) {
  return `/products?$top=12&$skip=${skip}&$q=${
    q === undefined ? "" : q
  }&$filter={"category":${JSON.stringify(Rayons)}}&$expand=category`;
}

export function createOrderUrl() {
  return `/orders/`;
}

export function orderByIdUrl( id) {
  return `/orders/${id}`;
}

export function OrderConfirmationUrl(id) {
  return `/orders/${id}/confirm`;
}
export function deleteOrdersUrl( id) {
  return `/orders/${id}`;
}

export function createPaymentUrl( id) {
  return `/orders/${id}/pay`;
}
