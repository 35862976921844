import React, { useState, useEffect } from "react";
import Slider from "components/Slider/slider";
import Service from "components/Service/service";
import Product from "components/Product/product";
import { Row, Col, Spinner } from "reactstrap";
import apiConfig from "config/apiConfig";
import { catProductsUrl } from "../../api/endpoints";
import Api from "api/api";
import { currentState } from "../../selector";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions";
const List = (props) => {
  const { BASE_URL } = apiConfig;
  const { inputSearch, Rayons } = useSelector(currentState) || {};
  const [fetching, setFetching] = useState(false);
  const [skip, setSkip] = useState(0);
  const [listProduct, setListProduct] = useState([]);
  const _ = require("lodash");
  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(
          catProductsUrl(skip, inputSearch, Rayons?.value)
        );
        setFetching(false);
        skip === 0
          ? setListProduct(data?.value)
          : setListProduct(_.unionBy(listProduct, data?.value, "id"));
      } catch {
        setFetching(false);
        // maybe display an error message
      }
    };

    fetchData();
  }, [skip, inputSearch, Rayons?.value]);

  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  return (
    <div className="">
      {Rayons.name ? (
        <>
          <div className="headerRayons text-bold mb-2">{Rayons.name}</div>
          <div className="breadcrumbRayons  text-Regular ml-4 mb-4">
            <span
              onClick={() => {
                actionsDispatch.setRayons({});
                actionsDispatch.setFeature({});
              }}
              style={{ cursor: "pointer" }}
            >{`Accueil${"  "}>`}</span>
            {`   Rayons${"  "}>  ${Rayons.name}`}
          </div>
        </>
      ) : (
        <>
          <Slider />
          <Service />
        </>
      )}

      <div className="app-main">
        <Row>
          {listProduct.map((item) => (
            <Col
              onClick={() => {
                props.actions.setCurrentProduct(item);
                props.history.push("/home/details");
              }}
              xs={12}
              sm={6}
              md={3}
            >
              <Product
                image={`${BASE_URL}${item?.imageUrl}`}
                name={item.name}
                description={item.longDescription}
                priceTtc={item.priceETtc}
                priceHt={item.priceE}
              />
            </Col>
          ))}
        </Row>
        {listProduct.length > 11 && (
          <>
            <Row>
              <Col xs="12" sm="12">
                <div className="footerAfficherPlus">
                  <span
                    onClick={() => setSkip(skip + 12)}
                    className="afficherPlus text-bold text-center mb-4"
                    style={{ cursor: "pointer" }}
                  >
                    {fetching ? <Spinner size="sm" /> : "Afficher plus"}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default List;
