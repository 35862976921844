import React, { useState, useMemo, useEffect } from "react";
import Slider from "components/Slider/slider";
import Service from "components/Service/service";
import Product from "components/Product/product";
import { Row, Col, Spinner, Button } from "reactstrap";
import apiConfig from "config/apiConfig";
import { catProductsUrl } from "../../api/endpoints";
import Api from "api/api";
import { currentState } from "../../selector";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions";
import { push } from "connected-react-router";
import * as actionsOrder from "modules/Orders/actions";
import { useCreateOrders } from "modules/Orders/hooks/useCreateOrders";
import { useUpdateOrders } from "modules/Orders/hooks/useUpdateOrders";

const Details = (props) => {
  const { BASE_URL } = apiConfig;
  const { current } = props.details;
  const dispatch = useDispatch();
  const actionsDispatchOrder = bindActionCreators(
    { ...actionsOrder },
    dispatch
  );
  const { inputSearch, Rayons, currentUser } = useSelector(currentState) || {};
  const actionsDispatch = bindActionCreators({ ...actions }, dispatch);
  const [orderBasket, setOrderBasket] = useState([]);
  const [{ fetchingUpdateOrders, errorUpdate }, updateOrders] = useUpdateOrders(
    orderBasket[0]?.id
  );
  const [{ fetchingOrders, error }, createOrders] = useCreateOrders();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api().get(`/orders/?$filter={"status": "basket"}`);
        setOrderBasket(data?.value);
        actionsDispatchOrder.emptyBasket();
      } catch (err) {
        console.log(err);
        // maybe display an error message
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (orderBasket.length > 0) {
      Array.isArray(orderBasket[0]?.products) &&
        orderBasket[0].products.map((item) =>
          actionsDispatchOrder.addBasket({
            ...item,
            can_add_to_basket: true,
            quantity: item.quantity,
          })
        );
    }
  }, [orderBasket]);
  // update  Order <<Vous avez déjà un panier en cours.>>
  useEffect(() => {
    if (error?.message === "Vous avez déjà un panier en cours.") {
      const payload = {
        products: props.basket.map((item) => ({
          product: item.id,
          quantity: item.quantity,
        })),
        status: "basket",
      };
      updateOrders(payload);
    }
  }, [fetchingOrders, error]);
  // update  Order
  useEffect(() => {
    if (orderBasket[0]?.id && orderBasket[0]?.status === "basket") {
      const payload = {
        products: props.basket.map((item) => ({
          product: item.id,
          quantity: item.quantity,
        })),
        status: "basket",
      };
      updateOrders(payload);
    }
  }, [props.basket, current?.quantity]);
  // create order

  const onCreate = (payload) => {
    createOrders(payload);
  };
  // Add to Basket
  const handleSelect = (item) => {
    actionsDispatchOrder.addBasket({
      ...item,
      can_add_to_basket: true,
      quantity: 1,
    });
    props.actions.setCurrentProduct({
      ...item,
      can_add_to_basket: true,
      quantity: 1,
    });

    onCreate({
      products: [
        {
          product: item.id,
          quantity: 1,
        },
      ],
      status: "basket",
    });
  };

  // More Quantity
  const addQuantity = (item) => {
    actionsDispatchOrder.addBasket({
      ...item,
      can_add_to_basket: true,
      quantity: item.quantity + 1,
    });
    props.actions.setCurrentProduct({
      ...item,
      can_add_to_basket: true,
      quantity: item.quantity + 1,
    });
  };

  // Less Quantity

  const lessQuantity = (item) => {
    if (item.quantity - 1 === 0) {
      actionsDispatchOrder.deleteBasket(item.id);
      props.actions.setCurrentProduct({
        ...item,
        can_add_to_basket: true,
        quantity: 0,
      });
    } else {
      actionsDispatchOrder.addBasket({
        ...item,
        can_add_to_basket: true,
        quantity: item.quantity - 1,
      });
      props.actions.setCurrentProduct({
        ...item,
        can_add_to_basket: true,
        quantity: item.quantity - 1,
      });
    }
  };

  return (
    <div className="detailsProduct">
      {Rayons?.name && (
        <>
          <div className="headerRayons text-bold mb-2">{Rayons?.name}</div>
          <div className="breadcrumbRayons  text-Regular ml-4 mb-4">
            <span
              onClick={() => {
                dispatch(push(`/home/list`));
                actionsDispatch.setRayons({});
                actionsDispatch.setFeature({});
              }}
              style={{ cursor: "pointer" }}
            >{`Accueil${"  "}>`}</span>
            {`   Rayons${"  "}>  ${Rayons?.name}${"  "}>  ${current?.name} `}
          </div>
        </>
      )}

      <div className="app-main mb-4">
        <Row className="detailProduct mb-4">
          <Col xs={12} md={4} className="image">
            <img
              width="250px"
              height="250px"
              alt="+"
              src={`${BASE_URL}${current?.imageUrl}`}
            />
            {current?.remiseE > 0 && currentUser?.priceCategory === "E" ? (
              <div className="promoProduct text-demi">-{current?.remiseE}%</div>
            ) : current?.remiseE > 0 ? (
              <div className="promoProduct text-demi">-{current?.remiseE}%</div>
            ) : (
              ""
            )}
          </Col>
          <Col xs={12} md={8} className="detail">
            <div className="title text-bold">{current?.name}</div>
            <div className="salePackaging text-regular">{current?.salePackaging}</div>
            <div className="description text-regular">
              {current?.shortDescription}
            </div>
            <div className="footerDetail">
              <div className="priceTtc text-bold">
                {current?.can_add_to_basket && current?.priceTtc
                  ? current?.priceTtc
                  : currentUser?.priceCategory === "A"
                  ? current?.priceATtc
                  : currentUser?.priceCategory === "B"
                  ? current?.priceBTtc
                  : currentUser?.priceCategory === "C"
                  ? current?.priceCTtc
                  : currentUser?.priceCategory === "D"
                  ? current?.priceDTtc
                  : currentUser?.priceCategory === "E" && current?.remiseE > 0
                  ? parseFloat(
                      current?.priceETtc -
                        (current?.priceETtc * current?.remiseE) / 100
                    ).toFixed(2)
                  : currentUser?.priceCategory === "E"
                  ? current?.priceETtc
                  : current?.priceETtc}{" "}
                € TTC
              </div>
              <div className="priceHt text-regular">
                {current?.can_add_to_basket && current?.price
                  ? current?.price
                  : currentUser?.priceCategory === "A"
                  ? current?.priceA
                  : currentUser?.priceCategory === "B"
                  ? current?.priceB
                  : currentUser?.priceCategory === "C"
                  ? current?.priceC
                  : currentUser?.priceCategory === "D"
                  ? current?.priceD
                  : currentUser?.priceCategory === "E" && current?.remiseE > 0
                  ? parseFloat(
                      current?.priceE -
                        (current?.priceE * current?.remiseE) / 100
                    ).toFixed(2)
                  : currentUser?.priceCategory === "E"
                  ? current?.priceE
                  : current?.priceE}{" "}
                € HT
              </div>

              {!current?.can_add_to_basket || current?.quantity === 0 ? (
                // <div
                //   onClick={() => {
                //     handleSelect();
                //   }}
                //   className="addBasket"
                // >
                //   <img alt="+" src={require("assets/img/addBasket.png")} />
                // </div>
                <div
                  className="btnBasket mt-2"
                  onClick={() =>
                    currentUser !== null
                      ? handleSelect(current)
                      : actionsDispatch.setSignIn(true)
                  }
                >
                  <div className="text text-demi">Ajouter au panier</div>
                  <div className="ml-4">
                    <img
                      width="25px"
                      height="25px"
                      alt="+"
                      src={require("assets/img/iconAddBasket.png")}
                    />
                  </div>
                </div>
              ) : (
                <div className="qteProduct mr-2">
                  <div
                    onClick={() =>
                      current?.quantity === 0 ? "" : lessQuantity(current)
                    }
                  >
                    <img
                      src={require("assets/img/iconDelete.png")}
                      className=""
                      width="24px"
                      alt=">"
                    />
                  </div>
                  <div>{current?.quantity}</div>
                  <div onClick={() => addQuantity(current)}>
                    <img
                      src={require("assets/img/iconAdd.png")}
                      className=""
                      width="24px"
                      alt=">"
                    />
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-bold mt-4">Description</div>
            <div>{current?.longDescription}</div>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div className="text-bold mt-4">Ingrédients</div>
            <div>
              Préparez en quelques minutes un repas typiquement breton grâce à
              nos 6 galettes de blé noir au sel de Guérande surgelées Toupargel.
              Cette recette traditionnelle à base de farine origine France, sans
              conservateur, sans colorant ni arôme artificiel, d'un diamètre
              généreux de 28 cm, vous permettra de confectionner de savoureuses
              galettes. De l'emblématique galette saucisse à la complète, ces
              galettes de blé noir au sel de Guérande surgelées Toupargel se
              plient à toutes vos envies !
            </div>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default Details;
